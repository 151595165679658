import React from "react";
import Card from "../../../UI/Card";

const Savings = () => {
  const Payments = [
    {
      id: 1,
      num: "01",
      name: "Emergency Preparedness",
      text: "Sometimes, expenses come unprepared, however, with the help of savings, it becomes easy to cater for unforeseen needs when they arise.",
    },
    {
      id: 2,
      num: "02",
      name: "Financial Discipline",
      text: "Regular savings instills financial discipline and responsible money management, promoting good money spending culture.",
    },
    {
      id: 3,
      num: "03",
      name: "Interest & Investment gains",
      text: "Rather than spend on unnecessary things, savings helps you to invest and earn returns or interest, allowing your money to grow over time.",
    },
    {
      id: 4,
      num: "04",
      name: "Reduced Financial Stress",
      text: "Knowing you have money saved somewhere reduce stress and anxiety associated with financial uncertainty, allowing you to focus on other aspect of your life.",
    },
  ];
  return (
    <main
      className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
    lg:max-w-[60rem] mx-auto flex flex-col sm:flex-row sm:justify-between"
    >
      <div className="flex flex-col justify-center md:pt-[1rem] pt-[3.12rem]">
        <h1 className="mac:text-[2.875rem] text-[1.37rem] leading-[2rem] md:text-[1.4rem] lg:text-[1.8rem] xl:text-[2.375rem] pb-[0.75rem] sm:pb-[0.5rem] font-bold md:leading-[3rem] lg:leading-[3.875rem] tracking-[-0.0575rem]">
          Savings comes with <br className="hidden sm:block" />
          Various
          <span className="text-[#017A59] pl-2 sm:pl-5">
            {" "}
            <br className="block sm:hidden" />
            Benefits
          </span>
        </h1>
        <p className="text-[#8A9099] md:text-[11px] lg:text-sm xl:text-[1.075rem] text-base leading-normal mac:text-[1.375rem] md:leading-normal font-medium lg:leading-[2.125rem]">
          Benefits of saving are numerous, this is
          <br className="hidden md:block" /> to give you insights as to why you
          should <br className="hidden md:block" />
          start saving today.
        </p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-[1.88rem] md:pt-[4rem] pt-[2.5rem] lg:pt-[12.06rem] pb-[7rem] place-content-start justify-start text-start items-start">
        {Payments.map((item) => (
          <Card
            key={item.id}
            className="xl:w-[23.3125rem] w-[21.375rem] h-[14.9875rem] p-[1.25rem] md:w-[13.5125rem] lg:w-[19.3125rem] md:h-[16.5125rem] lg:h-[18.8125rem] relative bg-[#EBFFF5] bg-opacity-40  rounded-xl shadow-md md:p-[1rem] lg:p-[1.82rem]"
          >
            {" "}
            <p className="text-[#017A59] md:text-[2rem] text-opacity-40 lg:text-[2.575rem] text-[1.375rem] xl:text-[2.875rem] font-bold leading-[2.1875rem] sm:leading-[3.875rem] tracking-[-0.0575rem]">
              {item.num}
            </p>
            <h1 className="lg:pb-[1rem] md:pb-[0.75rem] pb-[0.75rem] text-[1.125rem] text-[#017A59] md:text-base md:pt-[0.85rem] pt-[1rem] lg:pt-[1.5rem] lg:text-[1.1rem] xl:text-[1.375rem] font-semibold leading-[2rem] ">
              {item.name}
            </h1>
            <p className="text-[#1D1D1F] text-sm md:text-xs lg:text-[0.9rem] xl:text-[1.125rem] font-medium leading-normal lg:leading-[1.5rem]">
              {item.text}
            </p>
          </Card>
        ))}
      </div>
    </main>
  );
};
export default Savings;
