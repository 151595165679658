import React, { useState, useEffect, useRef, useCallback } from "react";
import manufacturing from "../../Assets/Business/manufacturing.png";
import imports from "../../Assets/Business/imports.png";
import Logistics from "../../Assets/Business/Logistics.png";
import Financial from "../../Assets/Business/Financial.png";
import constructions from "../..//Assets/Business/constructions.png";
import media from "../../Assets/Business/media.png";
import tech from "../../Assets/Business/tech.png";
import travel from "../../Assets/Business/travel.png";
import "../../App.css";

const SmallIndustries = () => {
  const [activeSections, setActiveSections] = useState("MANUFACTURINGSs");
  const [linePosition, setLinePosition] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const activeSectionRef = useRef(null);

  const handleSection = useCallback((section) => {
    setActiveSections(section);
    const sectionElement = document.getElementById(section);
    const line = document.querySelector(".line2");

    if (sectionElement && line) {
      const sectionTop = sectionElement.offsetTop - 5.78 * 16;
      const lineStartPosition = line.offsetTop;
      const lineEndPosition = sectionTop + sectionElement.offsetHeight / 2;

      animateLine(line, lineStartPosition, lineEndPosition);
    }
    // console.log("rerender4");
  }, []);

  const animateLine = (line, startPosition, endPosition) => {
    const duration = 300;
    let startTime;

    const moveLine = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      const newPosition =
        progress < duration
          ? startPosition +
            ((endPosition - startPosition) * progress) / duration
          : endPosition;

      line.style.top = `${newPosition}px`;

      if (progress < duration) {
        requestAnimationFrame(moveLine);
      }
    };

    requestAnimationFrame(moveLine);
    // console.log("rerender3");
  };

  useEffect(() => {
    handleSection("MANUFACTURINGSs");
    setInitialRender(false);
    // console.log("rerender1");
  }, [handleSection]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      let closestSection = sections[0];

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 5 * 16;
        if (window.scrollY >= sectionTop) {
          closestSection = section;
        }
      });

      const newActiveSection = closestSection.getAttribute("data-section");
      if (newActiveSection !== activeSectionRef.current) {
        setActiveSections(newActiveSection);
        activeSectionRef.current = newActiveSection;
      }
    };
    // console.log("rerender2");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSections]);

  const textsColor1 =
    activeSections === "MANUFACTURINGSs" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textsColor2 =
    activeSections === "IMPORTS & EXPORTS" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textsColor3 =
    activeSections === "FINANCIAL SOLUTION" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textsColor4 =
    activeSections === "TECHNOLOGYS" || initialRender ? "#017A59" : "#017A591A";
  const textsColor5 =
    activeSections === "TRAVELS & HOSPITALITY" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textsColor6 =
    activeSections === "LOGISTICSS" || initialRender ? "#017A59" : "#017A591A";
  const textsColor7 =
    activeSections === "CONSTRUCTIONS" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textsColor8 =
    activeSections === "MEDIAS & ENTERTAINMENT" || initialRender
      ? "#017A59"
      : "#017A591A";

  //the text
  const textsColors1 =
    activeSections === "MANUFACTURINGSs" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";
  const textsColors2 =
    activeSections === "IMPORTS & EXPORTS" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";
  const textsColors3 =
    activeSections === "FINANCIAL SOLUTION" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";
  const textsColors4 =
    activeSections === "TECHNOLOGYS" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textsColors5 =
    activeSections === "TRAVELS & HOSPITALITY" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";
  const textsColors6 =
    activeSections === "LOGISTICSS" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textsColors7 =
    activeSections === "CONSTRUCTIONS" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textsColors8 =
    activeSections === "MEDIAS & ENTERTAINMENT" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";

  return (
    <>
      <div className="lg:pt-[6.25rem] block sm:hidden pt-[3.13rem] md:pt-[8.25rem] text-center">
        <h2 className="text-[#1D1D1F] text-[1.375rem] px-5 md:text-[2.375rem] lg:text-[2.575rem] xl:text-[2.875rem] font-bold leading-[2.1875rem] tracking-[-0.0575rem]">
          <span className="text-[#017A59]">Industries</span> that can use our
          service
        </h2>
        <p className="text-center hidden pt-[0.5rem] md:text-base text-[#1D1D1F] lg:text-[1.175rem] xl:text-[1.375rem] font-medium lg:leading-[1.875rem] tracking-[-0.0275rem]">
          Being a cross-border payment platform makes our business services{" "}
          <br />
          useful to wide range of industries
        </p>
        <p className="text-center block sm:hidden pt-[0.5rem] text-sm text-[#8A9099] px-10 font-medium leading-normal tracking-[-0.0275rem]">
          Built to make business transactions easier, helping you sort payments
          seamlessly in quick time.
        </p>
      </div>
      <section className="flex sm:hidden space-x-[1.2rem] max-w-[21.5rem] mx-auto relative w-full">
        <div className="line-container4">
          <div
            className={`${"border-l-2 border-[#017a59] h-[5rem] transition-all duration-300"} line2`}
            // style={{ top: `calc(${linePosition}px - 4.38rem)` }}
          ></div>
        </div>
        <div className="flex flex-col justify-center items-center space-x-0">
          <div className="pt-[4.59rem] cursor-pointer">
            <h1
              style={{ color: `${textsColor1}` }}
              onClick={() => handleSection("MANUFACTURINGSs")}
              className={`pb-[0.3rem] text-[${
                activeSections === "MANUFACTURINGSs" || initialRender
                  ? "#017A59"
                  : "#017A591A"
              }] text-sm font-semibold leading-normal section`}
              data-section="MANUFACTURINGSs"
              id="MANUFACTURINGSs"
            >
              MANUFACTURING
            </h1>
            <p
              onClick={() => handleSection("MANUFACTURINGSs")}
              style={{ color: `${textsColors1}` }}
              className="pb-[1.58rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
            >
              Seamlessly pay suppliers globally, collect
              <br /> payment internationally and locally.
            </p>

            <h1
              style={{ color: `${textsColor2}` }}
              onClick={() => handleSection("IMPORTS & EXPORTS")}
              className={`pb-[0.3rem] text-[${
                activeSections === "IMPORTS & EXPORTS" ? "#017A59" : "#017A591A"
              }] text-sm font-semibold leading-normal  `}
              data-section="IMPORTS & EXPORTS"
              id="IMPORTS & EXPORTS"
            >
              IMPORT & EXPORT
            </h1>
            <p
              onClick={() => handleSection("IMPORTS & EXPORTS")}
              style={{ color: `${textsColors2}` }}
              className="pb-[1.58rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
            >
              Collect payment from international buyers, <br />
              and send funds to their local accounts.
            </p>

            <h1
              style={{ color: `${textsColor3}` }}
              onClick={() => handleSection("FINANCIAL SOLUTION")}
              className={`pb-[0.3rem] text-[${
                activeSections === "FINANCIAL SOLUTION"
                  ? "#017A59"
                  : "#017A591A"
              }] text-sm font-semibold leading-normal `}
              data-section="FINANCIAL SOLUTION"
              id="FINANCIAL SOLUTION"
            >
              FINANCIAL SOLUTIONS
            </h1>
            <p
              onClick={() => handleSection("FINANCIAL SOLUTION")}
              style={{ color: `${textsColors3}` }}
              className="pb-[1.58rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
            >
              Get liquidity access to power your cross-
              <br />
              border payments.
            </p>

            <h1
              style={{ color: `${textsColor4}` }}
              onClick={() => handleSection("TECHNOLOGYS")}
              className={`pb-[0.3rem] text-[${
                activeSections === "TECHNOLOGYS" || initialRender
                  ? "#017A59"
                  : "#017A591A"
              }] text-sm font-semibold leading-normal  `}
              data-section="TECHNOLOGYS"
              id="TECHNOLOGYS"
            >
              TECHNOLOGY
            </h1>
            <p
              onClick={() => handleSection("TECHNOLOGYS")}
              style={{ color: `${textsColors4}` }}
              className="pb-[1.58rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
            >
              Effortlessly manage global payments to
              <br />
              suppliers and efficiently receive payments.
            </p>
            <h1
              style={{ color: `${textsColor5}` }}
              onClick={() => handleSection("TRAVELS & HOSPITALITY")}
              className={`pb-[0.3rem] text-[${
                activeSections === "TRAVELS & HOSPITALITY"
                  ? "#017A59"
                  : "#017A591A"
              }] text-sm font-semibold leading-normal`}
              data-section="TRAVELS & HOSPITALITY"
              id="TRAVELS & HOSPITALITY"
            >
              TRAVEL & HOSPITALITY
            </h1>
            <p
              onClick={() => handleSection("TRAVELS & HOSPITALITY")}
              style={{ color: `${textsColors5}` }}
              className="pb-[1.58rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
            >
              Enjoy unforgettable travel experience with
              <br /> our secure payment platform.
            </p>

            <h1
              style={{ color: `${textsColor6}` }}
              onClick={() => handleSection("LOGISTICSS")}
              className={`pb-[0.3rem] text-[${
                activeSections === "LOGISTICSS" ? "#017A59" : "#017A591A"
              }] text-sm font-semibold leading-normal  `}
              data-section="LOGISTICSS"
              id="LOGISTICSS"
            >
              LOGISTICS
            </h1>
            <p
              onClick={() => handleSection("LOGISTICSS")}
              style={{ color: `${textsColors6}` }}
              className="pb-[1.58rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
            >
              Logistics businesses can collect and send <br />
              funds globally at a very low cost..
            </p>

            <h1
              style={{ color: `${textsColor7}` }}
              onClick={() => handleSection("CONSTRUCTIONS")}
              className={`pb-[0.3rem] text-[${
                activeSections === "CONSTRUCTIONS" ? "#017A59" : "#017A591A"
              }] text-sm font-semibold leading-normal `}
              data-section="CONSTRUCTIONS"
              id="CONSTRUCTIONS"
            >
              CONSTRUCTION
            </h1>
            <p
              onClick={() => handleSection("CONSTRUCTIONS")}
              style={{ color: `${textsColors7}` }}
              className="pb-[1.58rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
            >
              Expand your operations beyond border <br />
              with our cross-border payment solutions.
            </p>

            <h1
              style={{ color: `${textsColor8}` }}
              onClick={() => handleSection("MEDIAS & ENTERTAINMENT")}
              className={`pb-[0.3rem] text-[${
                activeSections === "MEDIAS & ENTERTAINMENT" || initialRender
                  ? "#017A59"
                  : "#017A591A"
              }] text-sm font-semibold leading-normal  `}
              data-section="MEDIAS & ENTERTAINMENT"
              id="MEDIAS & ENTERTAINMENT"
            >
              MEDIA & ENTERTAINMENT
            </h1>
            <p
              onClick={() => handleSection("MEDIAS & ENTERTAINMENT")}
              style={{ color: `${textsColors8}` }}
              className="pb-[1.58rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
            >
              Give global customers a truly modern <br />
              payments experience.
            </p>
          </div>

          <div className="flex flex-col justify-center items-center">
            {activeSections === "MANUFACTURINGSs" ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={manufacturing}
                  alt=""
                  className="object-cover mt-[4.62rem] w-full mx-auto"
                />
              </div>
            ) : "" || activeSections === "IMPORTS & EXPORTS" ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={imports}
                  alt=""
                  className="object-cover w-full mt-[4.62rem] "
                />
              </div>
            ) : "" || activeSections === "FINANCIAL SOLUTION" ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={Financial}
                  alt=""
                  className="object-cover w-full mt-[4.62rem]"
                />
              </div>
            ) : "" || activeSections === "TECHNOLOGYS" ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={tech}
                  alt=""
                  className="object-cover w-full mt-[4.62rem]"
                />
              </div>
            ) : "" || activeSections === "TRAVELS & HOSPITALITY" ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={travel}
                  alt=""
                  className="object-cover w-full mt-[4.62rem]"
                />
              </div>
            ) : "" || activeSections === "LOGISTICSS" ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={Logistics}
                  alt=""
                  className="object-cover w-full mt-[4.62rem]"
                />
              </div>
            ) : "" || activeSections === "CONSTRUCTIONS" ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={constructions}
                  alt=""
                  className="object-cover w-full mt-[4.62rem]"
                />
              </div>
            ) : "" || activeSections === "MEDIAS & ENTERTAINMENT" ? (
              <div className="flex flex-col justify-center items-center">
                <img
                  src={media}
                  alt=""
                  className="object-cover w-full mt-[4.62rem]"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SmallIndustries;
