import React from "react";
import card1 from "../../Assets/Business/card1.png";
import card2 from "../../Assets/Business/card2.png";

const Seamless = () => {
  return (
    <section className="bg-green-50/20 lg:h-[84.75rem] xl:h-[107.75rem] md:h-[69.75rem]">
      <div className="lg:pt-[6.25rem] pt-[3.13rem] md:pt-[4.25rem] mac:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto">
        <h3 className="text-[#1D1D1F] text-[1.375rem] leading-[2.1875rem] text-center md:text-[2.375rem] lg:text-[2.575rem] xl:text-[2.875rem] font-bold md:leading-[3rem] lg:leading-[3.875rem] tracking-[-0.0575rem]">
          Seemlessly{" "}
          <span className="text-[#017A59]">upscale your business</span>
        </h3>

        <p className="text-[#8A9099] hidden sm:block pt-[1rem] text-center md:text-base lg:text-[1.175rem] xl:text-[1.375rem] font-medium leading-normal lg:leading-[2.125rem]">
          Here are three easy steps to get your savings journey started{" "}
        </p>
        <p className="text-[#017A59] font-medium text-base lg:text-[22px] pt-[0.5rem] text-center">
          (COMING SOON)
        </p>
        <div className="lg:pt-[7.5rem] pt-[3.13rem] pb-[4.37rem] sm:pb-0 md:pt-[4.5rem] flex flex-col items-center space-y-[1.86rem] sm:space-y-[3.25rem]">
          <img
            src={card2}
            alt=""
            className="xl:w-[71.5rem] w-[21.275rem] h-[11.135rem] md:w-[39.5rem] md:h-[20.875rem] lg:w-[49.5rem] lg:h-[25.875rem] xl:h-[35.875rem] object-cover"
          />
          <img
            src={card1}
            alt=""
            className="xl:w-[71.5rem] w-[21.275rem] h-[11.135rem] md:w-[39.5rem] md:h-[20.875rem] lg:w-[49.5rem] lg:h-[25.875rem] xl:h-[35.875rem] object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default Seamless;
