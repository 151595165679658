import React from "react";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import CBN from "../../Assets/Business/CBN.png";

const Subscribe = () => {
  return (
    <section className="lg:pt-[18.25rem] pt-[7.25rem] md:pt-[10.25rem] md:max-w-[45rem] pro:max-w-[53rem] max-w-[22rem] mac:max-w-[85rem] xl:max-w-[75rem] lg:max-w-[60rem] mx-auto">
      <h4 className="text-white text-[1.125rem] sm:text-[1.5rem] font-semibold leading-[2.3125rem] tracking-[-0.03rem]">
        Follow our latest news
      </h4>
      <div className="pt-[0.5rem] pb-[1.5rem] flex gap-[1.21rem] sm:gap-[1.81rem]">
        <input
          type="text"
          className="w-[22rem] h-[3.6875rem] rounded-2xl pl-7 focus:outline-blue-400"
          placeholder="Add your email Address"
        />
        <IoArrowForwardCircleOutline className="text-white text-7xl" />
      </div>
      <p className="text-white border-b-2 sm:border-b-4 pb-[2.38rem] sm:pb-[4.38rem] border-white font-medium leading-[1.5rem] text-sm sm:text-[1.0625rem] tracking-[-0.02125rem]">
        Get information and updates on our financial services.
      </p>

      <p className="text-white sm:pt-[4.37rem] pt-[2.38rem] pb-[1rem] sm:pb-0 font-semibold leading-[1.5rem] text-sm sm:text-[1.125rem] tracking-[-0.0225rem]">
        Feedxpay and featured customers are financial technology companies and
        not a bank. Banking services are provided by our partnered banks who{" "}
        <br />
        are duly licensed by CBN. Feedxpay is a product of Cyberpro Limited
      </p>
      <div className="pt-5  flex gap-3 md:items-center pb-14 md:pb-0">
        <img
          src={CBN}
          alt=""
          className="md:w-[100px] md:h-[60px] w-[50px] h-[30px]"
        />
        <p className="text-white lg:text[18px] text-sm md:text-xs leading-[28px] font-medium md:font-semibold">
          Bank accounts and banking services are provided by FeedxPay's partner
          banks, members of NDIC.
        </p>
      </div>
    </section>
  );
};

export default Subscribe;
