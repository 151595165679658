import React, { useState, useEffect } from "react";
import Header from "./Header";
import Financial from "./Financial";
import Features from "./Features";
import Seamless from "./Seamless";
import Brand from "./Brand";
import Questions from "./Questions";
import Join from "./Join";
import Industries from "./Industries";
import Footer from "./Footer";
import Patners from "../Layout/Part/Patners";
import SmallIndustries from "./SmallIndustries";
import Nav from "./Nav";
import Company from "./Routers/Company";
import Support from "./Routers/Support";
import Solutions from "./Routers/Solutions";
import { landingPageEvent, landingPageOnboardingEvent, landingPageOnboardingEvent2 } from "../facebookPixelEvents/facebookPixelEvents";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const Business = () => {
  const [modals, setModals] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [supportsModal, setSupportsModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    landingPageOnboardingEvent();
    landingPageOnboardingEvent2();
    landingPageEvent();
    return () => {};
  }, []);

  const openModal = () => {
    setModals(true);
  };
  const closeModal = () => {
    setModals(false);
  };

  const handleModal = () => {
    setShowModals(true);
  };
  const handleCloseModal = () => {
    setShowModals(false);
  };

  const handleSupportModal = () => {
    setSupportsModal(true);
  };

  const handleCloseSupportModal = () => {
    setSupportsModal(false);
  };
  
  return (
    <div>
      <Nav
        onClicks={openModal}
        onOpens={handleModal}
        onSets={handleSupportModal}
      />
      {modals && <Solutions onHideMode={closeModal} />}
      {showModals && <Company onHideModal={handleCloseModal} />}
      {supportsModal && (
        <Support onHideSupportsModal={handleCloseSupportModal} />
      )}

      <Header />
      {/* <ToastContainer /> */}
      {/* <Trusted /> */}
      <Patners />
      <Financial />
      <Features />
      <Seamless />
      <Brand />

      <div className="hidden sm:block">
        <Industries />
      </div>
      <div className="block sm:hidden">
        <SmallIndustries />
      </div>
      <Questions />
      <Join />
      <Footer />
    </div>
  );
};

export default Business;
