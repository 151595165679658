import React from "react";
import security from "../../Assets/Business/security.svg";
import search from "../../Assets/Business/search.svg";
import tap from "../../Assets/Business/tap.svg";
import rocket from "../../Assets/Business/rocket.svg";
import Card from "../UI/Card";

const Brand = () => {
  const brand = [
    {
      id: 1,
      name: "security",
      img: security,
      text: "Elevate your business with an impenetrable shield of security. Our cutting-edge measures go beyond protection- they instill confidence. Your data and transactions are safeguarded, allowing you focus on other things.",
    },
    {
      id: 2,
      name: "transparency",
      img: search,
      text: "Navigate the business landscape with clarity at every turn. Transparency for us is not just a promise; it’s a philosophy. Understand the intricacies of your transactions, building a foundation of trust for lasting partnerships.",
    },
    {
      id: 3,
      name: "speed",
      img: rocket,
      text: "Redefine the pace of your operations with our agility. In the blink of an eye, witness your transactions materialize seamlessly. Our swift processes ensure that you gain a competitive edge, propelling your success forward.",
    },
    {
      id: 4,
      name: "accessibility",
      img: tap,
      text: "Our solutions are crafted or universal ease, ensuring that whether your business spans continents or communities, you can access our support effortlessly. Experience a world where your business is always within reach.",
    },
  ];
  return (
    <section className="xl:h-[80.0625rem] lg:h-[75.0625rem]">
      <div className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto pt-[3.13rem] md:pt-[4.25rem] lg:pt-[6.25rem]">
        <h2 className="text-[#1D1D1F] text-[1.375rem] leading-[2.1875rem] md:text-[2.357rem] text-center lg:text-[2.575rem] xl:text-[2.875rem] font-bold md:leading-[3rem] lg:leading-[3.875rem] tracking-[-0.0575rem]">
          <span className="text-[#017A59]"> Why</span> top brands use us
        </h2>

        <p className="pt-[1.38rem] text-base leading-normal md:text-base text-center text-[#8A9099] lg:text-[1.175rem] xl:text-[1.375rem] font-medium lg:leading-[2.125rem]">
          Where distinction seamlessly meets function. Join a league of{" "}
          <br className="hidden sm:block" />
          those who appreciate discreet excellence.
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:max-w-[40rem] xl:max-w-[75rem] max-w-[21rem] lg:max-w-[55rem] mx-auto gap-[1.8rem] md:gap-[2.25rem] lg:gap-[3.25rem] xl:place-content-evenly xl:justify-evenly pt-[2.5rem] lg:pt-[8.06rem] md:pt-[4.06rem] xl:pt-[10.06rem] pb-[4.38rem] sm:pb-0">
          {brand.map((item) => {
            return (
              <Card
                className="xl:w-[36rem] w-[21rem] h-[15.6875rem] md:w-[17.9rem] md:h-[17.975rem] lg:w-[26rem] lg:h-[19.975rem] xl:h-[22.375rem] shade p-[1.2rem] sm:p-[1.5rem] rounded-lg"
                key={item.id}
              >
                <img
                  src={item.img}
                  alt=""
                  className="w-[1.875rem] h-[1.875rem]"
                />
                <p className="text-[#017A59] capitalize md:text-base pt-[1rem] text-lg sm:pt-[1.63rem] lg:text-[1.175rem] xl:text-[1.375rem] font-semibold leading-normal md:leading-[2rem]">
                  {item.name}
                </p>
                <p className="pt-[0.75rem] text-sm text-[#1D1D1F] md:text-sm lg:text-[1.075rem] leading-normal xl:text-[1.375rem] font-medium lg:leading-[1.925rem] xl:leading-[2.125rem]">
                  {item.text}
                </p>
              </Card>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Brand;
