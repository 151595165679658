import React from "react";
import Card from "../../../UI/Card";

const Main = () => {
  const steps = [
    {
      id: 1,
      num: "01",
      title: "1",
      name: "Payment without boundaries",
      text: "Our Virtual Cards are widely accepted internationally, making them an ideal solution for seamless cross-border transactions.",
    },
    {
      id: 2,
      title: "2",
      num: "02",
      name: "USD transactions made easy",
      text: "Use our Virtual Cards for subscriptions and recurring payments. Easily track and manage your subscriptions without the need for manual intervention.",
    },
    {
      id: 3,
      title: "3",
      num: "03",
      name: "Highly Secured",
      text: "Virtual Cards offer an additional layer of security for online purchases, reducing the risk of unauthorized access and fraud.",
    },
  ];
  return (
    <section
      className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
    lg:max-w-[60rem] mx-auto"
    >
      <h1 className="md:text-[2.275rem] sm:text-left text-center text-[1.375rem] leading-[2.1875rem] lg:text-[2.875rem] md:py-[4.25rem] py-[3.12rem] lg:py-[4.25rem] font-bold sm:leading-[3.875rem] tracking-[-0.0575rem]">
        You need a <span className="text-[#017A59]">Virtual Card</span>
        <br />
        Here’s why
      </h1>
      <div className="flex flex-col sm:flex-row sm:justify-between md:pb-[4.75rem] lg:pb-[7.75rem] pb-[2.37rem] space-y-[3.25rem] sm:space-y-0">
        {steps.map((item) => (
          <div key={item.id}>
            <Card className=" flex justify-start mb-[2.5rem] lg:mb-[2rem] md:mb-[1rem] rounded-xl mx-auto w-[2.5rem] h-[2.5rem] sm:w-0 sm:h-0 sm:bg-transparent  bg-[#EBFFF5]">
              <p className="text-center  block py-2 px-4 text-[1.125rem] sm:hidden text-[#017A59] opacity-40 font-semibold  tracking-[-0.0575rem]">
                {item.title}
              </p>
            </Card>
            <p className="text-left hidden sm:flex text-[#017A59] opacity-40 font-bold md:text-[2.275rem] lg:text-[2.875rem] leading-[3.875rem] tracking-[-0.0575rem]">
              {item.num}
            </p>
            <h3 className="text-[#017A59] text-[1.125rem] text-center sm:text-left xl:text-[1.375rem] md:text-[0.9rem] lg:text-[1rem] font-semibold leading-[2rem] pb-[0.75rem]">
              {item.name}
            </h3>
            <p className="text-[#1D1D1F] px-2 sm:px-0 text-sm text-center sm:text-left lg:w-[17.5rem] md:w-[14rem] md:text-xs xl:w-[22rem] xl:text-[1.125rem] lg:text-sm font-medium leading-normal lg:leading-[1.5rem]">
              {item.text}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Main;
