import React, { useState, useEffect, useRef, useCallback } from "react";
import Money from "../../../Assets/Money.png";
import Virtual from "../../../Assets/Virtual.png";
import Invest from "../../../Assets/Invest.png";
import Saves from "../../../Assets/Saves.png";
import "../../../App.css";

const Payment2 = () => {
  const [activeSection, setActiveSection] = useState("PAYMENTSS");
  const [linePosition, setLinePosition] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const activeSectionRef = useRef(null);

  const handleSection = useCallback((section) => {
    setActiveSection(section);
    const sectionElement = document.getElementById(section);
    const line = document.querySelector(".line2");

    if (sectionElement && line) {
      const sectionTop = sectionElement.offsetTop - 5.78 * 16;
      const lineStartPosition = line.offsetTop;
      const lineEndPosition = sectionTop + sectionElement.offsetHeight / 2;

      animateLine(line, lineStartPosition, lineEndPosition);
    }
    // console.log("rerender4");
  }, []);

  const animateLine = (line, startPosition, endPosition) => {
    const duration = 300;
    let startTime;

    const moveLine = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      const newPosition =
        progress < duration
          ? startPosition +
            ((endPosition - startPosition) * progress) / duration
          : endPosition;

      line.style.top = `${newPosition}px`;

      if (progress < duration) {
        requestAnimationFrame(moveLine);
      }
    };

    requestAnimationFrame(moveLine);
    // console.log("rerender3");
  };

  useEffect(() => {
    handleSection("PAYMENTSS");
    setInitialRender(false);
    // console.log("rerender1");
  }, [handleSection]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".section");
      let closestSection = sections[0];

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 5 * 16;
        if (window.scrollY >= sectionTop) {
          closestSection = section;
        }
      });

      const newActiveSection = closestSection.getAttribute("data-section");
      if (newActiveSection !== activeSectionRef.current) {
        setActiveSection(newActiveSection);
        activeSectionRef.current = newActiveSection;
      }
    };
    // console.log("rerender2");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  const teextColor1 =
    activeSection === "PAYMENTSS" || initialRender ? "#017A59" : "#e8e9eb";
  const teextColor2 =
    activeSection === "VIRTUALS CARDS" || initialRender ? "#017A59" : "#e8e9eb";
  const teextColor3 =
    activeSection === "SAVINGSS" || initialRender ? "#017A59" : "#e8e9eb";
  const teextColor4 =
    activeSection === "INVESTMENTSS" || initialRender ? "#017A59" : "#e8e9eb";

  const textCollor1 =
    activeSection === "PAYMENTSS" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textCollor2 =
    activeSection === "VIRTUALS CARDS" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textCollor3 =
    activeSection === "SAVINGSS" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textCollor4 =
    activeSection === "INVESTMENTSS" || initialRender ? "#1D1D1F" : "#e8e9eb";

  return (
    <section className="flex sm:hidden space-x-[1.5rem] relative w-full">
      <div className="line-container2">
        <div
          className={`${"border-l-2 border-[#017a59] h-[5rem] transition-all duration-300"} line2`}
          // style={{ top: `calc(${linePosition}px - 4.38rem)` }}
        ></div>
      </div>
      <div className="flex flex-col">
        <div className="pt-[4.59rem] cursor-pointer">
          <h1
            style={{ color: `${teextColor1}` }}
            onClick={() => handleSection("PAYMENTSS")}
            className={`pb-[0.5rem] text-[${
              activeSection === "PAYMENTSS" || initialRender
                ? "#017A59"
                : "#017A591A"
            }] text-sm font-semibold leading-normal section`}
            data-section="PAYMENTSS"
            id="PAYMENTSS"
          >
            PAYMENTS
          </h1>
          <p
            style={{ color: `${textCollor1}` }}
            className="pb-[1.7rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
          >
            Make (Local and international transactions)
            <br />
            and scheduled payments
          </p>

          <h1
            style={{ color: `${teextColor2}` }}
            onClick={() => handleSection("VIRTUALS CARDS")}
            className={`pb-[0.5rem] text-[${
              activeSection === "VIRTUALS CARDS" ? "#017A59" : "#017A591A"
            }] text-sm font-semibold leading-normal  `}
            data-section="VIRTUALS CARDS"
            id="VIRTUALS CARDS"
          >
            VIRTUAL CARDS
          </h1>
          <p
            style={{ color: `${textCollor2}` }}
            className="pb-[1.7rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
          >
            One tool, one easy way to transact business
            <br />
            seemlessly.
          </p>

          <h1
            style={{ color: `${teextColor3}` }}
            onClick={() => handleSection("SAVINGSS")}
            className={`pb-[0.5rem] text-[${
              activeSection === "SAVINGSS" ? "#017A59" : "#017A591A"
            }] text-sm font-semibold leading-normal `}
            data-section="SAVINGSS"
            id="SAVINGSS"
          >
            SAVINGS
          </h1>
          <p
            style={{ color: `${textCollor3}` }}
            className="pb-[1.7rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
          >
            Attaining financial success is possible, but <br />
            only through strategic savings.
          </p>

          <h1
            style={{ color: `${teextColor4}` }}
            onClick={() => handleSection("INVESTMENTSS")}
            className={`pb-[0.5rem] text-[${
              activeSection === "INVESTMENTSS" || initialRender
                ? "#017A59"
                : "#017A591A"
            }] text-sm font-semibold leading-normal  `}
            data-section="INVESTMENTSS"
            id="INVESTMENTSS"
          >
            INVESTMENTS
          </h1>
          <p
            style={{ color: `${textCollor4}` }}
            className="pb-[1rem] text-[#1D1D1F] text-sm font-medium leading-normal tracking-[-0.025rem]"
          >
            Generate positive returns over a period of time by{" "}
            <br className="hidden" />
            investing with us today.
          </p>
        </div>

        {activeSection === "PAYMENTSS" ? (
          <div className="flex flex-col">
            <img src={Money} alt="" className="object-cover mt-[3.62rem] " />
          </div>
        ) : "" || activeSection === "VIRTUALS CARDS" ? (
          <div>
            <img
              src={Virtual}
              alt=""
              className="object-cover mt-[3.62rem] w-[24.375rem] h-[25.5rem]"
            />
          </div>
        ) : "" || activeSection === "SAVINGSS" ? (
          <div className="">
            <img src={Saves} alt="" className="object-cover mt-[3.62rem]" />
          </div>
        ) : "" || activeSection === "INVESTMENTSS" ? (
          <div className="">
            <img src={Invest} alt="" className="object-cover mt-[3.62rem] " />
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default Payment2;
