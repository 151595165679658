import React from "react";
import join from "../../Assets/Business/join.png";
import pngwing1 from "../../Assets/pngwing1.png";
import pngwing2 from "../../Assets/pngwing2.png";

const Join = () => {
  return (
    <section
      className="bgs lg:h-[42.5rem] xl:h-[49rem] h-[38rem] md:h-[28.1rem]"
      id="section1"
    >
      <div className="mac:pl-[4rem] xl:pl-[2.5rem] pl-[1.5rem] md:pl-[1.5rem] pro:pl-[2.5rem] lg:pl-[2rem] max-w-[100rem] mx-auto flex flex-col m-auto sm:flex-row xl:justify-around sm:justify-evenly">
        <div className="">
          <h1 className="sm:pb-[2rem] text-2xl pro:text-[1.69rem] lg:pb-[1rem] pb-[0.87rem] md:-[0.75rem] text-[#1D1D1F] lg:text-left text-left md:text-left md:text-[1.35rem] lg:text-[1.975rem] mac:text-[2.5rem] xl:text-[2rem] leading-[2.3125rem] font-medium lg:leading-[3.75rem] pt-[3.13rem] md:pt-[5.94rem] lg:pt-[11.94rem]">
            Gain swift access to your
            <br />
            <span className="text-[#017A59]">business account</span>
          </h1>
          <p className="xl:text-[1.375rem] hidden sm:block px-5 pro:text-base sm:px-0 md:text-xs lg:text-[15.5px] text-center sm:text-left text-[#1D1D1F] font-medium leading-normal lg:leading-[2.125rem]">
            Unlock easy and convenient access to
            <br className="hidden sm:block" />
            Feedxpay by getting on our app.
          </p>
          <p className="block sm:hidden text-sm text-left  text-[#1D1D1F] font-medium leading-normal">
            Our goal is to protect what’s most precious to our users their time,
            energy and resources
            <br className="hidden sm:block" />
          </p>
          <div className="flex md:pt-[1rem] pt-[1.5rem] lg:pt-[2rem] xl:pt-[5rem] justify-start gap-[2rem]">
            <img
              src={pngwing1}
              alt="get on ios"
              className="xl:w-[12.3125rem] w-[6rem] h-[2.25rem] mac:w-[14.3125rem] lg:w-[9.3125rem] object-contain sm:h-[7.3475rem] mt-[0.38rem]"
            />
            <img
              src={pngwing2}
              alt="get on ios"
              className="xl:w-[12.3125rem] w-[6rem] h-[1.75rem] mac:w-[14.3125rem] lg:w-[9.3125rem] object-contain sm:h-[6.25rem] mt-[0.74rem] sm:mt-[0.94rem]"
            />
          </div>
        </div>
        <div className="lg:pt-0 md:pt-[2.56rem] pro:pt-0 flex justify-center lg:flex-none pt-5 pr-0">
          <img
            src={join}
            alt="home"
            className="object-cover mac:w-[54.67113rem]  mac:h-[48.92563rem] xl:w-[50.87113rem] xl:h-[48.92563rem] lg:w-[43.375rem] lg:h-[42.4375rem] md:w-[29.375rem] md:h-[25.4375rem] pro:w-[32.375rem] pro:h-[27.875rem] w-[25.4975rem] h-[21.375rem]"
          />
        </div>
      </div>
    </section>
  );
};

export default Join;
