import React from "react";
import SuccessModal from "./UI/SuccessModal";
import check from "../Assets/check.png";

const Success = (props) => {
  return (
    <SuccessModal onClose={props.onClose}>
      <div className="flex flex-col items-center justify-center mt-[2rem]">
        <img src={check} alt="" className="w-[4.375rem] h-[4.375rem]" />
        <h1 className="text-[#1D1D1F] text-base text-center md:text-[1.125rem] font-semibold leading-[1.625rem] tracking-[-0.0225rem] mt-[2rem] md:mt-[3rem]">
          Thank you for contacting us we will get back to you shortly😀
        </h1>
      </div>
    </SuccessModal>
  );
};

export default Success;
