import React from "react";
import BModal from "../../UI/BModal";
import { Link } from "react-router-dom";
import Solutions1 from "../../../Assets/Business/Solutions1.png";
import Solutions2 from "../../../Assets/Business/Solutions2.png";
import Solutions3 from "../../../Assets/Business/Solutions3.png";
import Solutions4 from "../../../Assets/Business/Solutions4.png";
import Solutions5 from "../../../Assets/Business/Solutions5.png";
import Solutions6 from "../../../Assets/Business/Solutions6.png";
import Solutions7 from "../../../Assets/Business/Solutions7.png";
import Solutions8 from "../../../Assets/Business/Solutions8.png";

const Solutions = (props) => {
  const solutions = [
    {
      id: 1,
      name: "Bill Payment",
      img: Solutions1,
      text: "Pay your bills seemlessly with us",
    },
    {
      id: 2,
      name: "Expand",
      img: Solutions2,
      text: "Pay your bills seemlessly with us",
    },
    {
      id: 3,
      name: "Invoice",
      img: Solutions3,
      text: "Pay your bills seemlessly with us",
    },
    {
      id: 4,
      name: "Treasury Management",
      img: Solutions4,
      text: "Pay your bills seemlessly with us",
    },
    {
      id: 5,
      name: "Business Account",
      img: Solutions5,
      text: "Pay your bills seemlessly with us",
    },
    {
      id: 6,
      name: "Global Payroll",
      img: Solutions6,
      text: "Pay your bills seemlessly with us",
    },
    {
      id: 7,
      name: "Expense Management",
      img: Solutions7,
      text: "Pay your bills seemlessly with us",
    },
    {
      id: 8,
      name: "Startup",
      img: Solutions8,
      text: "Pay your bills seemlessly with us",
    },
  ];

  return (
    <BModal onClose={props.onHideMode}>
      <div className="lg:px-[2.19rem] px-[1.5rem] xl:pt-[2.12rem] pt-[2rem] lg:pt-[1.8rem]">
        <h1 className="text-[#017A59] text-sm lg:text-[1.125rem] sm:font-semibold leading-normal tracking-[-0.0225rem] pb-[1.5rem] border-b-2 border-[#E8E9EB]">
          Solutions
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3">
          {solutions.map((call, index) => {
            return (
              <div key={call.id} className="flex pt-[2rem] gap-[1rem]">
                <img
                  src={call.img}
                  alt=""
                  className="h-[2.9375rem] w-[2.9375rem]"
                />
                <div className="text-xs text-[#1D1D1F] lg:text-[1.125rem] font-semibold leading-normal tracking-[-0.0225rem]">
                  <h1 className="xl:pt-[0.3rem]">{call.name}</h1>
                  <p
                    className={` ${
                      index === 3 ? "lg:w-[22rem]" : ""
                    } text-[#8A9099] hidden text-xs lg:text-sm xl:text-[1.025rem] font-medium leading-normal`}
                  >
                    {call.text}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BModal>
  );
};

export default Solutions;
