import React, { useEffect } from "react";
import Nav from "./Nav";
import Card from "../UI/Card";
import Join from "./Join";
import Footer from "./Footer";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <>
      <Nav />
      {/* <div className="bgs  lg:h-[5574px] xl:h-[5374px] pt-[7.5rem] md:pt-[9rem] lg:pt-[14rem] flex justify-center"> */}
      <div className="bgs  lg:h-max xl:h-max pt-[7.5rem] md:pt-[9rem] lg:pt-[14rem] flex justify-center">

        {/* <Card className="lg:bg-white lg:w-[900px] xl:w-[1144px] lg:h-[5200px] xl:h-[5069px] p-[2.5rem] md:p-[3.5rem] lg:p-[2.5rem] pt-[3rem]"> */}
        <Card className="lg:bg-white lg:w-[900px] xl:w-[1144px] lg:h-max xl:h-max p-[2.5rem] md:p-[3.5rem] lg:p-[2.5rem] pt-[3rem]">
          <p className="text-[#017A59] text-[22px] pb-[1.5rem] sm:pb-[3.5rem] lg:pb-[4rem] text-left sm:text-center md:text-[32px] lg:text-[46px] leading-[64px] font-bold">
            Policy and Terms of Use
          </p>
          <p className="pb-[2rem] md:pb-0 xl:text-[20px] lg:text-[18px] text-sm text-justify font-medium leading-[34px]">
            Cyberpro Limited (hereinafter referred to as “feedxpay”, “we”, “us” or “our)
            provides <span className="font-bold">FEEDXPAY BUSINESS</span> and other related sites,
            online services, tools and content or data downloaded or accessed
            from the use of our service (collectively referred to as the
            “Service(s)”) to users subject to these Terms of Service and any
            posted guidelines, rules, policies, or specific terms of use
            applicable to the Services (collectively referred to as, the
            “Terms”), which may be updated from time to time. By accessing our
            Site or Services, you agree to comply with and be bound by the Terms
            and conditions stated below. <br />
            <br className="hidden sm:block" />
            1. Introduction <br />
            1.1 Feedxpay is a financial technology platform designed to offer
            cross-border payment solutions and an extensive range of financial
            services, including payment initiation, savings, investments,
            virtual account creation, expense management, budgeting, invoice
            creation, receipt management, bill payment, card requests, payment
            links, and virtual cards. This represents the initial MVP (Minimum
            Viable Product) release of the app. <br />
            1.2 Feedxpay integrates with third-party service providers,
            including Maplerad, Risevest, and Fincra. Maplerad facilitates
            secure payment transactions, Risevest enables fractional real
            estate, stocks, and Eurobond investments, while Fincra offers
            virtual business accounts.
            <br /> 1.3 Feedxpay and its partner entities are regulated and
            certified by the Central Bank of Nigeria (CBN) and the Securities
            and Exchange Commission (SEC), ensuring adherence to all relevant
            financial regulations and standards.
            <br /> 1.4 The app diligently follows Anti-Money Laundering (AML)
            and Counter Financing of Terrorism (CFT) regulations to prevent and
            mitigate financial crime risks. 2. Data Usage and Privacy Policy
            <br /> 2.1 Feedxpay collects data about your use of our Services.
            Usage data includes how and when you use Our Services, information
            furnished by you upon registration on this application and other
            means of data collection and your user preferences. We shall treat
            such data appropriately.
            <br /> 2.2 Feedxpay is committed to protecting user data and
            privacy. All user data collected, stored, or utilized by the app is
            handled in strict compliance with applicable data protection laws
            and regulations. The app employs industry-standard encryption,
            techniques to ensure dada anonymity, and robust security protocols
            to ensure the confidentiality and integrity of user information.
            <br /> 2.3 Users have the right to access, update, or delete their
            personal information stored within the app, subject to legal
            limitations and security considerations. <br />
            2.4 Feedxpay implements stringent security measures, including but
            not limited to encryption protocols, multi-factor authentication,
            firewalls, and regular security audits, to protect user data and
            transactions. <br />
            2.5 Users are responsible for maintaining the confidentiality of
            their account credentials and must refrain from sharing login
            information with unauthorized individuals.
            <br /> 3. User Eligibility and KYC/KYB Requirements
            <br /> 3.1 Users are required to undergo and complete the mandatory
            Know Your Customer (KYC) and Know Your Business (KYB) verification
            processes as mandated by regulatory authorities to access and use
            the platform. <br />
            3.2 Accurate and verifiable information must be provided during the
            registration and verification procedures. We reserve the right to
            terminate accounts of those who provide wrong and/or misleading
            information.
            <br /> 4. Prohibited Use of our services You shall not use, or
            encourage, promote, facilitate or instruct others to use our
            services or your account for any illegal, unlawful, harmful,
            restricted, or offensive use, or to create, transmit, store,
            display, or distribute or otherwise make available content that is
            illegal, unlawful, harmful, prohibited, or offensive. Examples of
            prohibited activities and content include: <br />
            a. Illegal activities - using the Site or Services in any manner
            that violates any laws or regulations, such as advertising,
            transmitting, or otherwise making available gambling sites or
            services or storing, disseminating, promoting, or facilitating child
            pornography.
            <br /> b. Harmful or fraudulent activities- engaging in activities
            that may be harmful to others or to Our operations or reputation,
            such as offering or disseminating speculative or fraudulent goods,
            services, schemes, or promotions; engaging in any deceptive
            practices or practices that pose or create a privacy or security
            risk to any person; attempting to impersonate another person or
            affiliation with an entity, including, but not limited to Feedxpay;
            or attempting to circumvent any bans or suspensions by Our
            moderators or to evade any content filters.
            <br /> c. Harmful content- posting or reposting any content, file,
            link or technology that may damage, interfere with, modify,
            intercept, or expropriate any system, program, or data, such as
            viruses, Trojan horses, worms, time bombs, and cancel bots;
            attempting to interfere in any way with Feedxpay operations or with
            another user’s account, including, but not limited to, mail or
            &”post”; bombing, news bombing, use of other flooding techniques,
            deliberate attempts to overload the system, or attempts to avoid
            message blocking.
            <br /> d. Training technologies - using the Site, Services, or any
            Output Content in any manner for purposes of developing or training
            Artificial Intelligence (Al) or other related technologies or for
            the creation of other applications.
            <br /> e. Other use restrictions - You may not
            <br /> a) copy, modify, or make derivative works of any part of the
            Site or Services, <br />
            b) incorporate any part of the Site or Services into Al or other
            technologies,
            <br /> c) translate or convert any part of the Site or Services,
            including any workflows, recipes, insights or other information,
            into another programming language, or port over any data or content
            from the Sites or Services into a different online site, product, or
            service, or <br />
            d) extract any underlying code, data, components or other
            information of the models, algorithms, and systems, utilized by Our
            services. f. Feedxpay strictly prohibits engagement in any crypto
            currency related activities in accordance with existing legal
            regulations.
            <br /> 5. Indemnity from Third-party agreements. In the event that
            the Al Services implement any components from, or connect to third
            parties, for the purpose of analyzing data obtained from Feedxpay,
            you will be subject to the terms of service, privacy policies, and
            usage policies of that third party. We are not responsible for the
            practices or policies of such third parties. You are solely
            responsible for reviewing and complying with any applicable
            Third-Party Terms. You are responsible for responding to any
            third-party claims regarding the use of your data.
            <br /> 6. Security Violations You shall not use our Services to
            violate the security or integrity of any network, computer or
            computing device, communications system, or application. Prohibited
            activities include unauthorized access to or use of any System such
            as attempting to probe, scan, or test the vulnerability of a System
            or to breach any security or authentication measures used by us and
            also conducting fraudulent and illegal transactions that violates
            the applicable laws of the Federal Republic of Nigeria. Examples of
            security violations under these Terms include, but are not limited
            to:
            <br /> a. Using viruses, bots, worms, or any other malicious
            computer code, files, or programs that interrupt, destroy, or limit
            the functionality of any System. <br />
            b. Interfering with, disrupting, or creating an undue burden on the
            Site or Services, including any networks or services connected to
            the Site.
            <br /> c. selling or otherwise transferring your profile;
            <br /> d. Collecting, using, or storing any personal data about
            another user without their express consent or permission from
            Feedxpay either electronically or other means and for any purpose.
            We reserve the right, at our sole discretion, to investigate any
            violation of these Terms or misuse of Our Services and may remove,
            disable your access to, or modify any content on the application
            that violates the Terms, applicable law or regulation. We may report
            any activity that we suspect violates any local or international law
            or regulation to the appropriate law enforcement agency, regulators,
            or appropriate body for the purposes of enforcing our legal rights
            and remedies. Our reporting may disclose relevant information about
            you and your use of our services or input of data. <br />
            7. Financial Charges and Transparency <br />
            7.1 Users will receive transparent and detailed information
            regarding any fees or charges associated with the services offered
            by Feedxpay. The app ensures clear disclosure of costs incurred by
            users.
            <br /> 7.2 Feedxpay reserves the right to modify fees or introduce
            new charges, providing prior notification to users in compliance
            with applicable laws and regulations. <br />
            8. Regulatory Compliance and Partner Banks
            <br /> 8.1 While not a banking institution, Feedxpay ensures that
            all partner banks providing services through the app are duly
            regulated by the Central Bank of Nigeria (CBN).
            <br /> 8.2 Feedxpay is committed to ongoing compliance with
            regulatory guidelines, working closely with partner entities to
            ensure regulatory adherence. 9. Intellectual Property Rights <br />
            9.1 All intellectual property rights, including but not limited to
            content, trademarks, logos, and proprietary software within the app,
            are the property of Feedxpay or its respective licensors.
            <br /> 9.2 Users are strictly prohibited from reproducing,
            distributing, or using any Feedxpay intellectual property without
            prior written consent. <br />
            10. Modification of Terms and Conditions 10.1 Feedxpay reserves the
            right to modify, update, or revise these terms and conditions at its
            discretion. Notification of changes will be communicated to users
            through appropriate channels, and continued use of the app implies
            acceptance of the revised terms. <br />
            10.2 Significant changes to the terms and conditions will be
            prominently displayed within the app and communicated via registered
            email addresses.
            <br /> 11. Legal Jurisdiction and Dispute Resolution 11.1 These
            terms and conditions fall under the jurisdiction of the Central Bank
            of Nigeria (CBN) and are governed by the laws of Nigeria. <br />
            11.2 Any disputes arising from the use of Feedxpay services shall be
            resolved through arbitration or legal proceedings within the
            jurisdiction of Nigeria.
            <br /> 12. User Feedback and Complaints 12.1 Users can report
            complaints, queries, or seek support by contacting
            help@feedxpay.com. Feedxpay is committed to addressing user concerns
            promptly and effectively.
            <br /> 12.2 The app strives to resolve user issues within reasonable
            timeframes and provide satisfactory resolutions.
            <br /> 12.3 Feedxpay actively encourages user feedback and
            suggestions to enhance its services. Monthly emails will be sent to
            users, providing a platform to share feedback and recommendations.
            <br />
            12.4 Users can reach out to dedicated customer service channels to
            report complaints or provide feedback, ensuring immediate attention
            and resolution.
          </p>

          <p className="text-[#017A59] text-[22px] pt-[1.5rem] sm:pt-[3.5rem] lg:pt-[5rem] text-left md:text-[32px] lg:text-[32px] leading-[40px] font-bold">
            Terms of Use: Currency Fluctuation Disclaimer
          </p>

          <p className="pb-[2rem] md:pb-0 xl:text-[20px] lg:text-[18px] text-sm text-justify font-medium leading-[32px] pt-8">
            
            1.	Introduction
            Welcome to FeedxPay. By accessing and using our currency exchange platform, you agree to comply with and be bound by the following terms and conditions. If you do not agree to these terms, please do not use our services.
            <br /><br />
            2.	Currency Fluctuation Disclaimer
            2.1.	Understanding Currency Fluctuations
            Currency exchange rates are subject to constant change due to a variety of factors, including but not limited to, market demand, economic conditions, geopolitical events, and government policies. These fluctuations can result in variations in the value of currencies over short periods.
            2.2.	No Liability for Losses
            FeedxPay provides currency exchange services as an intermediary and does not control or predict currency exchange rates. As such, FeedxPay will not be held liable for any losses or damages, direct or indirect, incurred by users due to fluctuations in currency exchange rates. This includes, but is not limited to, losses arising from:
            •	Delays in processing transactions
            •	Changes in exchange rates between the time of initiating and completing a transaction
            •	Market volatility
            2.3.	User Responsibility
            Users are solely responsible for making informed decisions regarding currency exchange transactions. It is advisable for users to monitor exchange rates and understand the risks associated with currency fluctuations before proceeding with any transaction on the platform.
            2.4.	No Guarantees on Exchange Rates
            FeedxPay does not guarantee the availability of any particular exchange rate at any given time. Rates displayed on the platform are indicative and subject to change without notice. The actual rate applicable to your transaction may differ from the rate displayed at the time of initiation.
            2.5.	Hold Situations and Rate Changes
            In cases where an exchange is initiated for a value to be held at a particular rate, but the rate changes before the value is received, the following conditions apply:
            •	The rate applicable at the time the value is received will be used for the transaction.
            •	FeedxPay will not be held liable for any differences in the value resulting from changes in the exchange rate during the hold period.
            •	Users should be aware of the potential for rate changes and plan accordingly.
            <br /><br />
            3.	 Indemnification
            By using FeedxPay, you agree to indemnify and hold harmless FeedxPay, its affiliates, officers, directors, employees, agents, and partners from any claims, liabilities, damages, losses, or expenses (including legal fees) arising out of or in any way connected with your use of the platform, including but not limited to losses due to currency fluctuations.
            <br /><br />
            4.	Changes to Terms
            FeedxPay reserves the right to modify these terms at any time. Any changes will be posted on our website, and it is the user's responsibility to review these terms periodically. Continued use of the platform after any changes constitutes acceptance of the new terms.
            <br /><br />
            5.	Governing Laws
            These terms and conditions shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria, without regard to its conflict of law principles. Any disputes arising out of or related to these terms shall be resolved exclusively in the courts of Nigeria.
            <br /><br />
            6.  Contact Information
            If you have any questions or concerns about these terms, please contact us at <span className="text-[#017A59] font-semibold">help@feedxpay.com</span>.

          </p>
        </Card>
      </div>
      <Join />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
