import React from "react";
import join from "../../../Assets/join.png";
import pngwing1 from "../../../Assets/pngwing1.png";
import pngwing2 from "../../../Assets/pngwing2.png";

const Join = () => {
  return (
    <section className="bgs lg:h-[47rem] h-[47rem] md:h-[36rem]" id="section1">
      <div className="md:max-w-[45rem] mac:max-w-[83rem] xl:max-w-[75rem] lg:max-w-[60rem] flex flex-col m-auto sm:flex-row xl:justify-around sm:justify-evenly">
        <div className="">
          <h1 className="sm:pb-[2rem] pb-[1rem] text-[#1D1D1F] lg:text-left text-center text-[1.875rem] xl:text-[3.1875rem] leading-[3rem] lg:text-[2.9187rem] font-medium lg:leading-[4.375rem] pt-[3.13rem] md:pt-[7.94rem] lg:pt-[11.94rem]">
            Join Feedxpay <br />
            <span className="text-[#017A59]">Sign up</span> in minutes
          </h1>
          <p className="xl:text-[1.375rem] px-5 sm:px-0 md:text-xs text-[1.125rem] text-center sm:text-left lg:text-[1.275rem] text-[#1D1D1F] font-medium leading-normal lg:leading-[2.125rem]">
            Our goal is to protect what’s most precious{" "}
            <br className="hidden sm:block" />
            to our users their time, energy and resources
          </p>
          <div className="flex md:pt-[2.5rem] lg:pt-[5rem] pt-0 justify-center sm:justify-start gap-[2rem]">
            <img
              src={pngwing1}
              alt="get on ios"
              className="xl:w-[14.3125rem] lg:w-[12.3125rem] object-contain h-[7.3475rem] w-[7rem]  mt-[0.38rem]"
            />
            <img
              src={pngwing2}
              alt="get on ios"
              className="xl:w-[14.3125rem] lg:w-[12.3125rem] object-contain w-[7rem] h-[6.25rem] mt-[0.94rem]"
            />
          </div>
        </div>
        <div className="lg:pt-[7.56rem] md:pt-[4.56rem] pb-[7.5rem] flex justify-center lg:flex-none pt-5">
          <img
            src={join}
            alt="home"
            className="object-cover lg:w-[29.375rem] lg:h-[36.4375rem] md:w-[21.375rem] md:h-[28.4375rem] w-[16.4975rem] h-[21.375rem]"
          />
        </div>
      </div>
    </section>
  );
};

export default Join;
