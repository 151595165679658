import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-[#017A59] bg-opacity-20 z-40"
      onClick={props.onClose}
    />
  );
};

const Overlay = (props) => {
  return (
    <div className="fixed iphone7:top-[44vh] iphone7:h-[22rem] iphoneXr:top-[32.5vh] pixel7:top-[32vh] top-[40vh] iphone12:top-[34vh] iphone14:top-[30.5vh] left-1/2 transform md:top-[22.5vh] translate-x-[-50%] xl:w-[95%] w-[90%] lg:h-[15.1875rem] md:w-[73%] md:h-[29.7rem] lg:w-[95%] h-[21.17rem] xl:h-[17.1875rem] bg-white p-0 sm:p-[1rem] rounded-xl shadow-2xl z-40">
      <div>{props.children}</div>
    </div>
  );
};
const portalElements = document.getElementById("overlays");
const BModal2 = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElements
      )}
      {ReactDOM.createPortal(
        <Overlay>{props.children}</Overlay>,
        portalElements
      )}
    </>
  );
};

export default BModal2;
