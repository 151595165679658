import React from "react";
import Card from "../../../UI/Card";

const Main = () => {
  const main = [
    {
      id: 1,
      num: "01",
      name: "Collaborative Spirit",
      text: "A great partner embraces collaboration, fostering an environment where teamwork and shared goals thrive. Our willingness to work together seamlessly amplifies the synergy of the partnership, creating a unified force that exceeds individual capabilities.",
    },
    {
      id: 2,
      num: "02",
      name: "Reliability and Consistency",
      text: " Dependability is the cornerstone of a strong partnership. Feedxpay, being a reliable partner consistently delivers on commitments, earning trust through their unwavering dedication to quality, timelines, and shared objectives.",
    },
    {
      id: 3,
      num: "03",
      name: "Innovative Mindset",
      text: " A forward-thinking partner brings innovation to the table. We are not just problem solvers; we actively seek opportunities for improvement. Our innovative mindset adds value to the partnership, making both parties fore-runners in the industry.",
    },
    {
      id: 4,
      num: "04",
      name: "Effective Communication",
      text: "Effective communication is pivotal in any successful partnership. A great partner ensures that channels of communication remain open, transparent, and efficient. We articulate ideas, share insights and actively listen to understand and navigate challenges together.",
    },
  ];
  return (
    <section className="max-w-[75rem] mx-auto">
      <h1 className="sm:pt-[6.25rem] pt-[3.13rem] text-[#1D1D1F] text-center md:text-[2.575rem] text-[1.375rem] lg:text-[2.875rem] font-bold leading-[3.875rem] tracking-[-0.0575rem]">
        <span className="text-[#017A59]">Why</span> become our partner
      </h1>

      <p className="sm:pt-[1.28rem] px-7 sm:px-0 text-center sm:pb-[8.69rem] pb-[2.5rem] text-[#8A9099] sm:text-[#1D1D1F] text-base md:text-sm lg:text-[1.175rem] xl:text-[1.375rem] font-medium leading-normal lg:leading-[1.875rem] tracking-[-0.0275rem]">
        We continuously seek truth, learn and improve in areas of{" "}
        <br className="hidden sm:block" />
        making important decision that as to do with work, relationship,{" "}
        <br className="hidden sm:block" />
        customer centricity and everything that is critical to our business.
      </p>
      <div className="grid sm:grid-cols-2 grid-cols-1 place-items-center gap-[2.25rem] md:gap-x-[0.6rem] md:pb-[3.25rem] lg:pb-[6.25rem]">
        {main.map((list) => (
          <Card
            key={list.id}
            className="xl:w-[36rem] lg:w-[27rem] w-[342px] h-[235px] xl:h-[22.575rem] md:h-[19.995rem] md:w-[20rem] lg:h-[21.375rem] rounded-md shade p-[1rem] sm:p-[1.25rem]"
          >
            <h4 className="text-[#017A5966] lg:pb-[1.37rem] md:pb-[0.75rem] pb-[0.5rem] text-[1.375rem] sm:text-[2.875rem] font-bold leading-[35px] md:leading-normal lg:leading-[2.975rem] tracking-[-0.0575rem]">
              {list.num}
            </h4>
            <h2 className="text-[#017A59] text-[1rem] sm:text-[1.175rem] xl:text-[1.375rem] leading-[19.75px] font-semibold md:leading-[2rem] sm:pb-[0.75rem] pb-[0.5rem]">
              {list.name}
            </h2>
            <p className="text-[#1D1D1F] text-[12.9px] md:text-[0.75rem] lg:text-[1rem] xl:text-[1.275rem] font-medium leading-[22px] md:leading-[1.5rem] lg:leading-[2.125rem]">
              {list.text}
            </p>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default Main;
