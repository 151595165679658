import React from "react";
import Card from "../../../UI/Card";
import { Link } from "react-router-dom";
import check from "../../../../Assets/chec.gif";

const Successful = () => {
  return (
    <section className="flex h-screen flex-col items-center justify-center">
      <Card className="w-full lg:w-[35rem] h-screen md:h-screen pt-[10rem] md:pt-[7rem] shadow-md">
        <div className="flex justify-center">
          <img
            src={check}
            alt=""
            className="object-contain w-[9rem] h-[9rem] md:w-[11rem] md:h-[11rem]"
          />
        </div>
        <h1 className="text-center font-semibold text-xl pt-[4rem] md:pt-[2rem]">
          Account deleted successfully
        </h1>
        <p className="text-center font-semibold text-base text-[#8A9099] ">
          We hope to have you around again soon
        </p>
        <div className="flex justify-center mt-10">
          <button className="bg-[#017A59] px-5 py-2 rounded-md text-white">
            <Link to="/business"> Home</Link>
          </button>
        </div>
      </Card>
    </section>
  );
};

export default Successful;
