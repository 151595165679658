import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoLogoInstagram } from "react-icons/io5";
import Logo2 from "../../Assets/Logo2.png";
import Subscribe from "./Subscribe";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="lg:h-[80.975rem] md:h-[87.875rem] h-full bg-[#017A59] ">
        <div className="mac:max-w-[83rem] xl:max-w-[75rem] lg:max-w-[60rem] md:max-w-[45rem] max-w-[22rem] pro:space-x-[5rem] md:space-x-[5rem] pro:max-w-[52rem] mx-auto flex flex-col sm:flex-row xl:gap-[6rem] lg:space-x-[2rem] pt-[6.25rem] text-white">
          <div className="">
            <img
              src={Logo2}
              alt=""
              className="object-cover md:w-[8.25rem] md:h-[1.5rem] lg:w-[11.25rem]  lg:h-[2rem] pro:h-[1.8rem] pro:w-[11.25rem] xl:w-[14.75rem] xl:h-[2.6rem]"
            />
            <div className="text-3xl flex md:gap-5 gap-[1.72rem] lg:gap-[2.25rem] py-[2.25rem] xl:mr-5">
              <FaFacebookF className="text-white xl:text-3xl lg:text-3xl text-4xl" />
              <a href="https://www.linkedin.com/company/feedxpay/">
                <FaLinkedinIn className="text-white xl:text-3xl lg:text-3xl text-4xl" />
              </a>
              <a href="https://twitter.com/feedxpay?s=21&t=9UbgU9s2lOoKwSNXKIkytA">
                <FaXTwitter className="text-white xl:text-3xl lg:text-3xl text-4xl" />
              </a>
              <a href="https://www.instagram.com/feedxpay/?igshid=MzRlODBiNWFlZA%3D%3D">
                <IoLogoInstagram className="text-white xl:text-3xl lg:text-3xl text-4xl" />
              </a>
            </div>
          </div>

          <div className="grid grid-cols-2 space-x-0 lg:grid-cols-4  xl:gap-[3rem] mac:gap-[2rem] lg:space-x-[2rem] pro:space-x-[2.5rem] pt-10 sm:pt-0">
            <div className="space-y-[1rem] block sm:hidden w-[12rem]">
              <p className="sm:text-[1.375rem] text-base font-semibold from leading-[2rem]">
                Solutions
              </p>

              <p className="sm:text-[1.0625rem] text-xs font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Business Account</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Treasury Management</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="">Bills Payment</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> invoice</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Expand</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Global Payroll</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Expense Management</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Startup</Link>
              </p>
            </div>
            <div className="space-y-[1rem] sm:space-y-[1.5rem] block sm:block xl:ml-10 mac:ml-12 pro:ml-10 pl-3">
              <p className="text-[1rem] sm:text-[1.375rem] font-semibold from leading-[2rem]">
                Company
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/aboutUs"> About US</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <a
                  href="https://medium.com/@feedxpay"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/careers">Careers</Link>
              </p>
            </div>
            <div className="space-y-[1rem] sm:space-y-[1.5rem] hidden sm:block w-[12rem]">
              <p className="sm:text-[1.375rem] text-base font-semibold from leading-[2rem]">
                Solutions
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Business Account</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Treasury Management</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="">Bills Payment</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> invoice</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Expand</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Global Payroll</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Expense Management</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to=""> Startup</Link>
              </p>
            </div>
            <div className="space-y-[1rem] capitalize sm:space-y-[1.5rem] pt-[3.36rem] lg:pl-[2.3rem] xl:pl-[1rem] lg:w-[11rem] mac:w-full md:pt-[1.5rem] lg:pt-0">
              <p className="sm:text-[1.375rem] text-base font-semibold from leading-[2rem]">
                Support
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/contactSales">contact sales</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/faq">FAQs</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <a
                  href="https://t.me/feedxpay"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Community
                </a>
              </p>
              <p className="sm:text-[1.0625rem] text-xs font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/partner"> Become Patners</Link>
              </p>
              <p className="sm:text-[1.0625rem] text-[0.8rem] font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/support">support</Link>
              </p>
            </div>
            <div className="space-y-[1rem] sm:space-y-[1.5rem] pt-[3.36rem] lg:pl-[1rem] xl:pl-0 md:pt-[1.5rem] lg:pt-0">
              <p className="sm:text-[1.375rem] text-base font-semibold from leading-[2rem]">
                Legal
              </p>

              <p className="sm:text-[1.0625rem] text-xs font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                <Link to="/privacy"> Terms Use</Link>
              </p>

              <p className="sm:text-[1.0625rem] text-xs font-medium leading-[1.5rem] tracking-[-0.02125rem]">
                {" "}
                Policies
              </p>
            </div>
          </div>
        </div>
        <Subscribe />
      </footer>
    </>
  );
};

export default Footer;
