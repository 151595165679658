import App from "../../../App";
import { useParams } from "react-router-dom";
import Support1 from "./FAQs/Support1";
import Support2 from "./Partner/Support2";
import Support4 from "./ContactSales/Support4";
import Support5 from "./Support/Support5";

const DynamicSupport = () => {
  const { pageName } = useParams();

  if (pageName === "faqs") {
    return <Support1 />;
  } else if (pageName === "partners") {
    return <Support2 />;
  } else if (pageName === "contactSale") {
    return <Support4 />;
  } else if (pageName === "supports") {
    return <Support5 />;
  } else {
    return <App />;
  }
};
export default DynamicSupport;
