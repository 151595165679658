export const landingPageEvent = () => {
    window.fbq('track', 'Lead-LandingPage');
}

export const landingPageOnboardingEvent = () => {
    window.fbq('track', 'Lead-Onboarding');
}

export const landingPageOnboardingEvent2 = () => {
    window.fbq('track', 'Lead-Onboarding2');
}