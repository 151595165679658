import React from "react";
import Card from "../../../UI/Card";
import Logo from "../../../../Assets/Logo.png";
import hugging from "../../../../Assets/hugging.png";
import deletes from "../../../../Assets/deletes.png";
import { Link } from "react-router-dom";

const Delete = () => {
  return (
    <section className="bgs h-screen flex flex-col items-center md:justify-center">
      <article className="md:pt-[0.5rem] pt-[6rem] pb-[2rem]">
        <img
          src={Logo}
          alt=""
          className="object-cover w-[10.7rem] h-[1.895rem]"
        />
      </article>
      <Card className="w-[342px] h-[583px]  md:w-[32.5rem] md:h-[28.375rem]  rounded-xl bg-white ">
        <div className="justify-center flex flex-col items-center space-y-[1.5rem] pt-[3rem] md:pt-0">
          <img
            src={deletes}
            alt=""
            className="object-cover w-[85px] h-[85px] md:w-[5rem] md:h-[5rem] mt-6"
          />
          <h4 className="text-xl font-semibold leading-[2rem]">
            Delete Account
          </h4>
          <p className="md:px-10 px-7 text-center leading-[18.5px] md:leading-normal text-base font-medium">
            We do not wish to see you go. Kindly reconsider staying with us.
          </p>
        </div>
        <div className="flex gap-5 md:gap-16 justify-center mt-[12rem]">
          <button className="border-[#017A59] border-2 transition-all duration-300 hover:bg-[#017A59] hover:text-white  md:mt-[3.62rem] w-[139px] py-3 rounded-md text-[#017A59] text-[1.125rem] font-medium leading-[1.5rem] tracking-[-0.0225rem]">
            <Link to="/otp"> No, Delete</Link>
          </button>
          <button className="bg-[#017A59] hover:border-[#017A59] hover:bg-white hover:border-2  hover:text-[#017A59] md:mt-[3.62rem] w-[139px] py-3 rounded-md text-white text-[1.125rem] font-medium leading-[1.5rem] tracking-[-0.0225rem]">
            <Link to="/stay">Yes, Stay</Link>
          </button>
        </div>
      </Card>
    </section>
  );
};

export default Delete;
