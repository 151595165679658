import React from "react";
import Card from "../UI/Card";
import { IoIosArrowRoundForward } from "react-icons/io";
import currrency from "../../Assets/Business/currency.png";
import Mask from "../../Assets/Business/Mask.png";
import Mask2 from "../../Assets/Business/Mask2.png";
import cable from "../../Assets/Business/cable.png";
import cable2 from "../../Assets/Business/cable2.png";
import infla from "../../Assets/Business/infla.png";
import infla2 from "../../Assets/Business/infla2.png";

const Financial = () => {
  return (
    <section className="business lg:h-[143.5rem] md:h-[126.5rem] xl:h-[172.5rem] mac:h-[177.5rem] h-[202.5rem]">
      <div className="lg:pt-[6.25rem] pt-[3.13rem] md:pt-[4.25rem] mac:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto">
        <h3 className="text-[#1D1D1F] text-[1.375rem] leading-[2.1875rem] text-center md:text-[2.295rem] lg:text-[2.595rem] xl:text-[2.875rem] font-bold md:leading-[3rem] lg:leading-[3.875rem] tracking-[-0.0575rem]">
          All your <span className="text-[#017A59]">financial modules </span>
          <br className="hidden sm:block" />
          in <br className="block sm:hidden" />
          one platform
        </h3>
        <p className="text-[#8A9099] text-sm md:text-base text-center pt-[0.5rem] sm:pt-[1rem] lg:text-[1.175rem] xl:text-[1.375rem] font-medium leading-normal lg:leading-[2.125rem]">
          Built to make business transactions easier, helping you{" "}
          <br className="hidden sm:block" />
          sort payments seamlessly in real time.
        </p>
        <div className="grid sm:grid-cols-2 grid-cols-1 gap-y-[2.5rem] lg:gap-x-[4.12rem] md:gap-x-[3.12rem] md:gap-y-[3.38rem]  lg:gap-y-[4.38rem] pt-[2.94rem] sm:pt-[6.25rem]">
          <Card className="mac:w-[40.0625rem] w-[21.075rem] h-[43.5625rem] md:h-[47.5375rem] md:w-[20.5625rem] pro:w-[23.9625rem] xl:w-[35.5625rem] lg:w-[26.5625rem] mx-auto lg:h-[51.5375rem] xl:h-[65.9375rem] mac:h-[68.9375rem] sha bg-white rounded-lg p-[1rem] sm:p-[2rem]">
            <div>
              <img
                src={currrency}
                alt=""
                className="object-cover w-[19.375rem] h-[23.625rem] md:w-[21.5625rem] md:h-[25.5625rem] xl:w-[35.8125rem] xl:h-[37.5rem]"
              />
            </div>
            <p className="bg-[#EBFFF5] text-xs sm:mt-[2.25rem] mt-[2rem] md:text-[11.5px] md:w-full xl:w-[25.625rem] h-[3rem] p-3 rounded-md text-[#017A59] lg:text-[1rem] lg:w-[22.7rem] xl:text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
              MULTI-CURRENCY CORPERATE ACCOUNT
            </p>
            <h2 className=" pt-[1.5rem] text-lg md:text-[1.07rem] text-[#1D1D1F] lg:text-[1.3rem] xl:text-[1.9rem] mac:text-[2.125rem] font-bold leading-[2.875rem] tracking-[-0.0425rem]">
              NGN, USD, EUR, GBP
            </h2>
            <p className="text-[#8A9099] text-sm md:text-[0.65rem] pt-[0.75rem] sm:pt-[1.5rem] lg:text-sm mac:text-[1.4rem] xl:text-[1.1rem] font-medium lg:leading-[1.9rem] xl:leading-[2.3125rem] tracking-[-0.03rem]">
              We provide everything you need to operate across
              <br className="hidden sm:block" /> borders, From multi-currency
              account to wallets.
            </p>
            <p className="text-[#1D1D1F] pt-[3.12rem] text-xs md:pt-[4.25rem] md:text-xs lg:pt-[6.45rem] lg:text-base xl:pt-[6.55rem] mac:pt-[9.85rem] xl:text-[1.125rem] flex gap-2 font-medium leading-normal">
              Explore Feedxpay Business Account{" "}
              {/* <IoIosArrowRoundForward className="text-2xl lg:mt-[1px] md:-mt-1 -mt-1" /> */}
            </p>
          </Card>
          <Card className="mac:w-[40.0625rem] w-[21.075rem] h-[43.5625rem] md:h-[47.5375rem] md:w-[20.5625rem] pro:w-[23.9625rem] xl:w-[35.5625rem] lg:w-[26.5625rem] lg:h-[51.5375rem] mx-auto xl:h-[65.9375rem] mac:h-[68.9375rem] sha bg-white rounded-lg p-[1rem] sm:p-[2rem]">
            <div>
              <img
                src={cable2}
                alt=""
                className="object-cover block w-[19.975rem] md:hidden -mt-3  h-[24.525rem]"
              />
              <img
                src={cable}
                alt=""
                className="object-cover hidden md:block  h-[23.625rem] md:w-[21.5625rem] md:h-[25.5625rem] xl:w-[35.8125rem] xl:h-[37.5rem]"
              />
            </div>
            <p className="bg-[#EBFFF5] text-xs w-[12rem] sm:mt-[2.25rem] mt-[2rem] md:text-[11.5px] md:w-[13.7rem] xl:w-[17.75rem] h-[3rem] p-3 rounded-md text-[#017A59] lg:text-[1rem] lg:w-[16.7rem] xl:text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
              INTERNATIONAL TRANSFER
            </p>
            <h2 className=" pt-[1.5rem] text-lg md:text-[1.07rem] text-[#1D1D1F] lg:text-[1.3rem] xl:text-[1.9rem] mac:text-[2.125rem] font-bold leading-[2.875rem] tracking-[-0.0425rem]">
              Make cross-border transactions
            </h2>
            <p className="text-[#8A9099] text-sm md:text-[0.65rem] pt-[0.75rem]  sm:pt-[1.5rem] lg:text-sm mac:text-[1.4rem] xl:text-[1.1rem]  font-medium lg:leading-[1.9rem]  xl:leading-[2.3125rem] tracking-[-0.03rem]">
              The first all-in-one solution for businesses to make{" "}
              <br className="hidden lg:block" /> global payment in over 20+
              currencies with best
              <br className="hidden lg:block" /> institutional rate.
              <br className="hidden slg:block" />
            </p>
            <p className="text-[#1D1D1F] pt-[3.12rem] text-xs md:pt-[2.75rem] md:text-xs lg:pt-[4.35rem] lg:text-base xl:pt-[3.98rem] mac:pt-[7.75rem] xl:text-[1.125rem] flex gap-2 font-medium leading-normal">
              Explore Feedxpay Business Account{" "}
              {/* <IoIosArrowRoundForward className="text-2xl lg:mt-[1px] md:-mt-1" /> */}
            </p>
          </Card>
          <Card className="mac:w-[40.0625rem] md:h-[47.5375rem] w-[21.075rem] h-[43.5625rem] md:w-[20.5625rem] pro:w-[23.9625rem] xl:w-[35.5625rem] lg:w-[26.5625rem] lg:h-[51.5375rem] xl:h-[65.9375rem] mx-auto mac:h-[68.9375rem] sha bg-white rounded-lg p-[1rem] sm:p-[2rem]">
            <div>
              <img
                src={Mask2}
                alt=""
                className="object-cover w-[19.375rem] h-[23.625rem] block md:hidden"
              />
              <img
                src={Mask}
                alt=""
                className="object-cover w-[19.375rem] h-[23.625rem] hidden md:block md:w-[21.5625rem] md:h-[25.5625rem] xl:w-[35.8125rem] xl:h-[37.5rem]"
              />
            </div>
            <p className="bg-[#EBFFF5] text-xs w-[11.125rem] sm:mt-[2.25rem] mt-[2rem] md:text-[11.5px] md:w-[13.7rem] xl:w-[16.375rem] h-[3rem] p-3 rounded-md text-[#017A59] lg:text-[1rem] lg:w-[15.7rem] xl:text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
              LIQUIDITY ACCESSIBILITY
            </p>
            <h2 className=" pt-[1.5rem] text-lg md:text-[1.07rem] text-[#1D1D1F] lg:text-[1.5rem] xl:text-[1.9rem] mac:text-[2.125rem] font-bold leading-[2.875rem] tracking-[-0.0425rem]">
              Get quick access to cash
            </h2>
            <p className="text-[#8A9099] text-sm md:text-[0.65rem] pt-[0.75rem] sm:pt-[1.5rem] lg:text-sm mac:text-[1.4rem] xl:text-[1.1rem] font-medium lg:leading-[1.9rem]  xl:leading-[2.3125rem] tracking-[-0.03rem]">
              Elevate your business with top-tier liquidity and
              <br className="hidden lg:block" /> cutting-edge technology
              solutions. Access USD,
              <br className="hidden lg:block" /> EUR, GBP, AND 20+ other
              currencies effortlessly at <br className="hidden lg:block" />{" "}
              competitive institutional rates.
            </p>
            <p className="text-[#1D1D1F] pt-[0.85rem] text-xs md:pt-[1.75rem] md:text-xs lg:pt-[2.35rem] lg:text-base xl:pt-[2.75rem] mac:pt-[5.45rem] xl:text-[1.125rem] flex gap-2 font-medium leading-normal">
              Explore Feedxpay Business Account{" "}
              {/* <IoIosArrowRoundForward className="text-2xl lg:mt-[1px] md:-mt-1" /> */}
            </p>
          </Card>
          <Card className="mac:w-[40.0625rem] md:h-[47.5375rem] w-[21.075rem] h-[43.5625rem] md:w-[20.5625rem] pro:w-[23.9625rem] xl:w-[35.5625rem] lg:w-[26.5625rem] lg:h-[51.5375rem] mx-auto xl:h-[65.9375rem] mac:h-[68.9375rem] sha bg-white rounded-lg p-[1rem] sm:p-[2rem]">
            <div>
              <img
                src={infla2}
                alt=""
                className="object-cover block md:hidden w-[19.375rem] -mt-3 h-[24.625rem]"
              />
              <img
                src={infla}
                alt=""
                className="object-cover hidden md:block w-[19.375rem] h-[23.625rem] md:w-[21.5625rem] md:h-[25.5625rem] xl:w-[35.8125rem] xl:h-[37.5rem]"
              />
            </div>
            <p className="bg-[#EBFFF5] text-xs w-[9.5625rem] sm:mt-[2.25rem] mt-[2rem]  md:text-[11.5px] md:w-[13.7rem] xl:w-[17.75rem] h-[3rem] p-3 rounded-md text-[#017A59] lg:text-[1rem] lg:w-[15.7rem] xl:text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
              FINANCIAL ANALYSIS
            </p>
            <h2 className=" pt-[1.5rem] text-lg text-[#1D1D1F] md:text-[1.07rem] lg:text-[1.3rem] xl:text-[1.9rem] mac:text-[2.125rem] font-bold leading-[2.875rem] tracking-[-0.0425rem]">
              Make cross-border transactions
            </h2>
            <p className="text-[#8A9099] text-sm md:text-[0.65rem] pt-[0.75rem] sm:pt-[1.5rem] lg:text-sm mac:text-[1.4rem] xl:text-[1.1rem] font-medium lg:leading-[1.9rem]  xl:leading-[2.3125rem] tracking-[-0.03rem]">
              Track your business activities, analyze{" "}
              <br className="hidden lg:block" />
              performance, optimize your operations, and{" "}
              <br className="hidden lg:block" />
              ultimately succeed in achieving your goals.{" "}
            </p>
            <p className="text-[#1D1D1F] pt-[3.12rem] text-xs md:pt-[2.75rem] md:text-xs lg:pt-[4.35rem] lg:text-base xl:pt-[4.75rem] mac:pt-[7.75rem] xl:text-[1.125rem] flex gap-2 font-medium leading-normal">
              Explore Feedxpay Business Account{" "}
              {/* <IoIosArrowRoundForward className="text-2xl lg:mt-[1px] md:-mt-1" /> */}
            </p>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default Financial;
