import React from "react";
import Card from "../../../UI/Card";
import check from "../../../../Assets/chec.gif";

const Stay = () => {
  return (
    <section className="flex h-screen flex-col items-center md:justify-center">
      <Card className="w-full lg:w-[35rem] h-screen md:h-screen pt-[12rem] md:pt-[7rem] shadow-md">
        <div className="flex justify-center">
          <img
            src={check}
            alt=""
            className="object-contain w-[9rem] h-[9rem] md:w-[11rem] md:h-[11rem]"
          />
        </div>
        <h1 className="text-center font-semibold text-xl pt-[2rem]">
          Thank you for staying
        </h1>
        <p className="text-center font-semibold text-base text-[#8A9099] ">
          You can sign into your account on the app
        </p>
        <div className="flex justify-center mt-10">
          <button className="bg-[#017A59] px-5 py-2 rounded-md text-white">
            <a
              href="https://business.feedxpay.com/signin"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Go back{" "}
            </a>
          </button>
        </div>
      </Card>
    </section>
  );
};

export default Stay;
