import { useParams } from "react-router-dom";
import Company2 from "./Routers/Careers/Company2";
import Company1 from "./Routers/AboutUs2/AboutUs2/Company1";
// import Main3 from "../Layout/Routes/Blog/Main3";
import Business from "./Business";

const BDynamic2 = () => {
  const { pageName } = useParams();

  if (pageName === "aboutUs") {
    return <Company1 />;
  } else if (pageName === "careers") {
    return <Company2 />;
  } else if (pageName === "3") {
    window.location.href = "https://medium.com/@feedxpay";
    // return null;
  } else {
    return <Business />;
  }
};
export default BDynamic2;
