import React, { useState } from "react";
import Card from "../../../UI/Card";
import Logo from "../../../../Assets/Logo.png";
import hugging from "../../../../Assets/hugging.png";
import Input from "../OpenAcct/Input";
import { FaEyeSlash } from "react-icons/fa";
import { LiaEyeSolid } from "react-icons/lia";
import { Link } from "react-router-dom";

const SignIn = () => {
  const [hide, setHide] = useState(false);

  const handleHide = () => {
    setHide((prevHide) => prevHide);
  };
  return (
    <section className="bgs h-screen flex flex-col items-center md:justify-center">
      <article className="md:pt-[0.5rem] pt-[6rem] pb-[2rem]">
        <img
          src={Logo}
          alt=""
          className="object-cover w-[10.7rem] h-[1.895rem]"
        />
      </article>
      <Card className="w-[342px] h-[583px] md:w-[32.5rem] md:h-[27.375rem]  rounded-xl bg-white ">
        <div className="flex justify-start md:flex-row md:justify-center md:gap-2 gap-3 px-6 md:px-[5.25rem]">
          {" "}
          <h2 className="md:pt-[1.5rem] pt-[2rem] font-semibold capitalize text-left text-[#1D1D1F] text-[24px] md:text-[1.25rem] leading-normal">
            stay with us
          </h2>
          <img
            src={hugging}
            alt=""
            className="object-cover w-[1.5rem] h-[1.595rem] mt-9 md:mt-6"
          />
        </div>
        <p className="md:px-[5.25rem] px-6 text-left md:text-center pt-1 text-sm font-medium">
          we will miss you
        </p>
        <form>
          <div className="pt-[2.5rem] md:pt-[2.01rem] px-6 md:px-[5.25rem] ">
            <Input
              label="Email Address"
              type="text"
              className="w-full h-[2.75rem] pl-5 border-2 border-[#E8E9EB] rounded-xl focus:outline-blue-400 mb-[2rem]"
            />
            <div className="relative">
              <Input
                label="Password"
                type={hide ? "text" : "password"}
                className={`w-full h-[2.75rem] pl-5 border-2 border-[#E8E9EB] rounded-xl focus:outline-blue-400
              `}
              />
              {hide ? (
                <LiaEyeSolid
                  className="absolute top-11 right-3 cursor-pointer"
                  onClick={handleHide}
                />
              ) : (
                <FaEyeSlash
                  className="absolute top-11 right-3 cursor-pointer"
                  onClick={handleHide}
                />
              )}
            </div>

            {/* <div className="pt-[1rem] flex justify-between">
              <span className="flex gap-1">
                <Input
                  type="checkbox"
                  className="w-[0.75rem] h-[0.75rem] -mt-1 border-2 border-[#E8E9EB] rounded-xl focus:outline-blue-400"
                />
                <p className="text-[#8A9099] text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem]">
                  Remember Me
                </p>
              </span>

              <h5 className="text-[#017A59] font-medium leading-normal tracking-[-0.0175rem]">
                Forgot Password
              </h5>
            </div> */}
            <button className="bg-[#017A59] mt-[6.2rem] md:mt-[3.62rem] w-full py-4 rounded-xl text-white text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
              <Link to="/delete"> Delete Account</Link>
            </button>
            {/* <p className="text-[0.875rem] text-center font-medium leading-normal tracking-[-0.0175rem] pt-[1rem]">
              Don’t have an account?
              <span className="text-[#017A59]">Sign Up </span>
            </p> */}
          </div>
        </form>
      </Card>
    </section>
  );
};

export default SignIn;
