import React from "react";
import BModal2 from "../../UI/BModal2";
import { Link } from "react-router-dom";
import Company1 from "../../../Assets/Business/Company1.png";
import Company2 from "../../../Assets/Business/Company2.png";
import Company3 from "../../../Assets/Business/Company3.png";

const Company = (props) => {
  const company = [
    {
      id: 1,
      name: "About Us",
      img: Company1,
      text: "Know more about the company.",
      route: "/aboutUs",
    },
    {
      id: 2,
      img: Company2,
      name: "Careers",
      text: "Become a part of us",
      route: "/careers",
    },
    {
      id: 3,
      img: Company3,
      name: "Blog",
      text: "Get Access to our Updates",
      externalLink: "https://medium.com/@feedxpay",
    },
  ];

  return (
    <BModal2 onClose={props.onHideModal}>
      <div className="px-[1.5rem] lg:px-[2.19rem] xl:pt-[2.12rem] pt-[2rem] lg:pt-[2rem]">
        <h1 className="text-[#017A59] capitalize text-sm font-medium sm:text-[1.125rem] sm:font-semibold leading-normal tracking-[-0.0225rem] pb-[1.5rem] border-b-2 border-[#E8E9EB]">
          Company
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3">
          {company.map((call) =>
            call.route ? (
              <Link to={call.route} key={call.id}>
                <div className="flex pt-[2rem] gap-[1rem]">
                  <img
                    src={call.img}
                    alt=""
                    className="h-[2.9375rem] w-[2.9375rem]"
                  />
                  <div className="text-xs text-[#1D1D1F] lg:text-base xl:text-[1.125rem] font-semibold leading-normal tracking-[-0.0225rem]">
                    <h1 className="pt-[0.3rem]">{call.name}</h1>
                    <p className="text-[#8A9099] text-xs lg:text-sm xl:text-[1.125rem] font-medium leading-normal">
                      {call.text}
                    </p>
                  </div>
                </div>
              </Link>
            ) : (
              <a
                href={call.externalLink}
                target="_blank"
                rel="noopener noreferrer"
                key={call.id}
              >
                <div className="flex pt-[2rem] gap-[1rem]">
                  <img
                    src={call.img}
                    alt=""
                    className="h-[2.9375rem] w-[2.9375rem]"
                  />
                  <div className="text-xs text-[#1D1D1F] lg:text-base xl:text-[1.125rem] font-semibold leading-normal tracking-[-0.0225rem]">
                    <h1 className="pt-[0.3rem]">{call.name}</h1>
                    <p className="text-[#8A9099] text-xs lg:text-sm xl:text-[1.125rem] font-medium leading-normal">
                      {call.text}
                    </p>
                  </div>
                </div>
              </a>
            )
          )}
        </div>
      </div>
    </BModal2>
  );
};

export default Company;
