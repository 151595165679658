import React, { useState } from "react";
import sec from "../../Assets/Business/sec.png";
import payroll from "../../Assets/Business/payroll.png";
import expense from "../../Assets/Business/expense.png";
import sec1 from "../../Assets/Business/sec1.svg";
import sec2 from "../../Assets/Business/sec2.svg";
import secs2 from "../../Assets/Business/secs2.png";
import sec3 from "../../Assets/Business/sec3.svg";
import Card from "../UI/Card";

const Features = () => {
  const [activeText, setActiveText] = useState(0);
  const data = [
    {
      id: 1,
      img: sec,
      img2: secs2,
      icon: sec1,
      name: "Currency Exchange",
      text: "Convert currency at competitive rate",
    },
    {
      id: 2,
      img: expense,
      icon: sec2,
      name: "Expense Management",
      text: "Exchange your wallet balance to your preferred currency",
    },
    {
      id: 3,
      img: payroll,
      icon: sec3,
      name: "Global Payroll",
      text: "Pay your workers around the globe",
      text2: "Make all your payments easily from one source",
    },
  ];

  const handleSection = (index) => {
    setActiveText(index);
    console.log("active", index);
  };
  return (
    <section className="lg:h-[66.3125rem] md:h-[57.3125rem]">
      <div className="lg:pt-[6.25rem] md:pt-[4.25rem] pt-[3.13rem] mac:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto">
        <h1 className="text-[#1D1D1F] text-[1.375rem] px-5 sm:px-0 leading-[2.1875rem] md:text-[2.275rem] text-center lg:text-[2.575rem] xl:text-[2.875rem] font-bold md:leading-[3rem] lg:leading-[3.875rem] tracking-[-0.0575rem]">
          <span className="text-[#017A59]">Features</span> that works for all
          types <br className="hidden sm:block " />
          of businesses
        </h1>
        <p className="text-[#8A9099] hidden sm:block text-center pt-[1rem] md:text-base lg:text-[1.175rem] xl:text-[1.375rem] leading-normal lg:leading-[2.125rem] font-medium">
          We continuously seek truth, learn and improve in
          <br />
          areas of making important decision that as to do with work,{" "}
        </p>
        <div className="sm:pt-[7.5rem] pt-[1.5rem] flex flex-col sm:flex-row sm:justify-between">
          <div className="flex flex-col space-y-[2.5rem]">
            {data.map((items, index) => {
              return (
                <Card
                  key={items.id}
                  onClick={() => handleSection(index)}
                  className={`${
                    activeText === index ? "bg-green-50/40" : ""
                  } lg:w-[31.995rem] w-[21.375rem] md:w-[23.795rem] xl:w-[34.875rem] transition-all cursor-pointer duration-300 ease-in-out md:h-[6.95rem] lg:h-[9.25rem] ${
                    activeText === index ? "border-transparent" : ""
                  } border-2 border-[#E8E9EB] p-[1rem] rounded-lg`}
                >
                  <div className="flex sm:gap-[1.5rem] gap-[0.75rem]">
                    <img src={items.icon} alt="" />
                    <div>
                      <h5 className="text-[#017A59] text-sm md:text-[1.075rem] lg:text-[1.375rem] font-semibold leading-[2rem]">
                        {items.name}
                      </h5>
                      <p
                        className={`text-[#1D1D1F] md:pt-[0.45rem] lg:pt-[0.85rem] text-sm md:text-xs lg:text-[1.25rem] font-medium leading-normal lg:leading-[1.75rem] tracking-[-0.025rem] ${
                          index === 0 ? "lg:w-[20.75rem]" : ""
                        } ${index === 1 ? "lg:w-[19.75rem]" : ""} ${
                          index === 0 ? "w-[13.85125rem]" : ""
                        } ${index === 1 ? "w-[15.25rem]" : ""} ${
                          index === 2 ? "hidden sm:block" : ""
                        } `}
                      >
                        {items.text}
                      </p>
                      {index === 2 ? (
                        <p
                          className={`text-[#1D1D1F] md:pt-[0.45rem] lg:pt-[0.85rem] text-sm md:text-xs lg:text-[1.25rem] font-medium leading-[1.75rem] tracking-[-0.025rem]  ${
                            index === 2 ? "block sm:hidden" : ""
                          }`}
                        >
                          {items.text2}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
          <div className="pt-[3.13rem] pb-[4.19rem] sm:pb-0 sm:pt-0">
            {activeText === 0 ? (
              <div>
                <img
                  src={data[0].img}
                  alt=""
                  className="object-contain hidden md:flex w-[21.375rem]  md:w-[22.5625rem] md:h-[28.5625rem] mac:w-[46.5625rem] lg:w-[29.5625rem] xl:w-[39.5625rem] lg:h-[31.6875rem]"
                />
                <img
                  src={data[0].img2}
                  alt=""
                  className="object-fill block md:hidden w-full"
                />
              </div>
            ) : activeText === 1 ? (
              <img
                src={data[1].img}
                alt=""
                className="object-contain w-[21.375rem] md:w-[17.5625rem] h-[17.4375rem] md:h-[30.5625rem] mac:w-[46.5625rem] lg:w-[22.5625rem] xl:w-[34.5625rem] lg:h-[31.6875rem]"
              />
            ) : (
              <img
                src={data[2].img}
                alt=""
                className="object-contain w-[21.375rem] md:w-[19.5625rem] h-[17.4375rem] md:h-[30.5625rem] mac:w-[46.5625rem] lg:w-[24.5625rem] xl:w-[37.5625rem] lg:h-[31.6875rem]"
              />
            )}
            {/* {data[activeText] && (
              <img
                src={data[activeText].img}
                alt=""
                className="object-contain w-[46.5625rem] h-[31.6875rem]"
              />
            )} */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
