import React from "react";
import Card from "../../../UI/Card";

const Interest = () => {
  const Interest = [
    {
      id: 1,
      num: "01",
      name: "Attractive ROI",
      text: "One of the many things that distinguish us from others, is our ROI. We offer juicy returns on our investment plans depending on the period you are investing for.",
    },
    {
      id: 2,
      num: "02",
      name: "Access to Resources",
      text: "We allow our customers full access to our investment resources; blog posts and FAQs so as to give better clarity on everything regarding investment.",
    },
    {
      id: 3,
      num: "03",
      name: "Historical Performance",
      text: "Feedxpay has a track record of good historical performance. We have  been able to deliver best services under various market conditions.",
    },
    {
      id: 4,
      num: "04",
      name: "Cost and Fees",
      text: "We operate a transparent payment platform. All cost and fees associated with our investment plans are not hidden.",
    },
  ];
  return (
    <section
      className="mac:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] xl:max-w-[75rem] md:max-w-[45rem]
    lg:max-w-[59rem] mx-auto flex flex-col sm:flex-row sm:justify-between"
    >
      <div className="flex flex-col justify-center pt-[0.5rem] ">
        <h1 className="lg:text-[2rem] leading-[2.1875rem] text-[1.375rem] md:text-2xl xl:text-[2.875rem] pt-[3.13rem] sm:pt-[5.25rem] font-bold md:leading-[2.35rem] lg:leading-[3.35rem] xl:leading-[3.875rem] tracking-[-0.0575rem]">
          We have our <br className="hidden sm:block" />{" "}
          <span className="text-[#017A59]">customers’</span>
          <br />
          interest at heart
        </h1>
        <p className="text-[#8A9099] text-base leading-normal md:text-xs pt-[0.2rem] md:leading-normal lg:text-sm xl:text-[1.175rem] mac:text-[1.375rem] font-medium lg:leading-[2.125rem]">
          We endeavor to put the interest of our
          <br className="" />
          customers at the core of our decision{" "}
          <br className="hidden sm:block" />
          making.
        </p>
      </div>
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-[1.88rem] pt-[3.25rem] md:pt-[8.06rem] lg:pt-[12.06rem] md:pb-[3.5rem] lg:pb-[7rem] place-content-start justify-start text-start items-start">
        {Interest.map((item) => (
          <Card
            key={item.id}
            className="xl:w-[23.3125rem] w-[21.375rem] h-[14.6875rem] p-[1.25rem] md:w-[13.3125rem] md:h-[15.3125rem] lg:w-[18.3125rem] lg:h-[17.8125rem] xl:h-[18.8125rem] relative bg-[#EBFFF5] bg-opacity-40  rounded-xl md:p-[0.8rem] shadow-md lg:p-[1.2rem] xl:p-[1.82rem]"
          >
            {" "}
            <p className="text-[#017A59] text-[1.375rem] leading-[2.1875rem] md:text-xl md:leading-normal text-opacity-40 lg:text-[2rem] xl:text-[2.875rem] font-bold lg:leading-[3rem] xl:leading-[3.875rem] tracking-[-0.0575rem]">
              {item.num}
            </p>
            <h1 className="xl:pb-[1rem] text-lg pb-[0.75rem] pt-[1rem] lg:pb-[0.75rem] text-[#017A59] md:pt-[0.75rem] md:text-base md:pb-[0.5rem] xl:pt-[1.3rem] lg:pt-[1rem] lg:text-[1.1rem] xl:text-[1.375rem] font-semibold lg:leading-normal xl:leading-[2rem] ">
              {item.name}
            </h1>
            <p className="text-[#1D1D1F] text-[0.9rem] leading-normal md:text-xs lg:text-[1rem] xl:text-[1.025rem] mac:text-[1.125rem] font-medium md:leading-[1.5rem]">
              {item.text}
            </p>
          </Card>
        ))}
      </div>
    </section>
  );
};

export default Interest;
