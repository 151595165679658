import React from "react";
import Splash from "../../../../../Assets/Splash.png";

const Main = () => {
  return (
    <main className="xl:max-w-[83rem] pro:max-w-[52rem] max-w-[21.5rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto flex flex-col-reverse sm:flex-row sm:justify-evenly pt-[3.13rem] sm:pt-[6.5rem] pb-[6rem]">
      <div>
        <img
          src={Splash}
          alt=""
          className="object-top rounded-md w-[21.375rem] h-[21.375rem] xl:w-[34.125rem] lg:w-[28.125rem] md:w-[22.125rem] md:h-[29.575rem] lg:h-[43.575rem] xl:h-full"
        />
      </div>

      <div className="pb-[2.5rem] sm:pb-0">
        <h5 className="sm:text-[1.5rem] uppercase text-sm text-[#017A59] font-semibold leading-[2.3125rem] tracking-[-0.03rem] pb-[1.37rem] sm:pb-[2rem]">
          Our story
        </h5>
        <h2 className="text-[#1D1D1F] text-[1.125rem] md:text-base leading-normal lg:leading-[3rem] lg:text-[1.5rem] xl:text-[2rem] font-bold pb-[1.5rem]">
          We sure have a story to tell of how <br />
          Feedxpay came to be
        </h2>
        <p className="xl:text-[1.375rem] text-base leading-[1.75rem] lg:text-[1rem] md:text-xs font-medium md;leading-normal lg:leading-[2.125rem] text-[ #1D1D1F]">
          Welcome to FeedXPay, where our journey is not
          <br className="hidden sm:block" />
          just a narrative but a commitment to redefining
          <br className="hidden sm:block" /> how individuals and businesses
          navigate the
          <br className="hidden sm:block" /> financial landscape.
        </p>
        <p className="pt-[2rem] lg:text-[1rem] text-base leading-[1.75rem] md:text-xs xl:text-[1.375rem] font-medium md:leading-normal lg:leading-[2.125rem] text-[ #1D1D1F]">
          Our journey began with a vision to simplify{" "}
          <br className="hidden sm:block" /> financial processes, eliminate
          barriers to doing
          <br className="hidden sm:block" /> business internationally, and
          empower users to
          <br className="hidden sm:block" /> take control of their financial
          destinies. We <br className="hidden sm:block" />
          embarked on a mission to create a platform that
          <br className="hidden sm:block" /> goes beyond conventional banking,
          offering a<br className="hidden sm:block" /> comprehensive suite of
          services that cater to the
          <br className="hidden sm:block" /> diverse needs of our users.
        </p>{" "}
        <p className="pt-[2rem] lg:text-base xl:text-[1.375rem] text-base leading-[1.75rem] md:text-xs font-medium md:leading-normal lg:leading-[2.125rem] text-[ #1D1D1F]">
          FeedXPay is not just a platform, it's a commitment{" "}
          <br className="hidden sm:block" />
          to your financial success.
        </p>
      </div>
    </main>
  );
};

export default Main;
