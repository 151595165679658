import React, { useEffect } from "react";
import Header from "./Header";
import Join from "../../Join/Join";
import Footer from "../../Footer/Footer";

const Support1 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, []);
  return (
    <>
      <div className="overflow-hidden">
        <Header />
        <Join />
        <Footer />
      </div>
    </>
  );
};

export default Support1;
