import React from "react";
import Logo1 from "../../../Assets/Business/Logo1.png";

const Patners = () => {
  return (
    <section className="lg:h-[20.75rem] md:h-[18.75rem] h-[11.625rem] w-full max-w-[83rem] mx-auto overflow-hidden relative">
      <h1 className="text-center text-[#017A59] text-[1.375rem] sm:text-[2.875rem] md:pt-[3.25rem] lg:pt-[2.85rem] xl:pt-[1.25rem] pt-[2.05rem] font-bold leading-[3.875rem] tracking-[-0.0575rem]">
        Trusted by
      </h1>
      <div className="w-full">
        <div className="flex justify-evenly lg:justify-around animate absolute left-0 overflow-visible">
          <div className="flex sm:pt-[4.94rem] pt-[4.94rem] justify-center mr-[2rem] lg:mr-0 items-center space-x-[3rem] xl:space-x-[5rem] lg:space-x-[4rem]  w-50">
            <div>
              <img src={Logo1} alt="" className="" />
            </div>
          </div>
          <div className="flex justify-center items-center space-x-[3rem] mr-[2rem] lg:mr-0 xl:space-x-[5rem] lg:space-x-[4rem] w-50 sm:pt-[4.94rem] pt-[4.94rem]">
            <div>
              <img src={Logo1} alt="" />
            </div>
          </div>
          <div className="flex justify-center items-center space-x-[4rem] mr-[2rem] lg:mr-0 xl:space-x-[5rem] lg:space-x-[4rem] w-50 sm:pt-[4.94rem] pt-[4.94rem]">
            <div>
              <img src={Logo1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Patners;
