import React from "react";
import Modal3 from "../../UI/Modal3";
import { Link } from "react-router-dom";

const Support = (props) => {
  const support = [
    {
      id: 1,
      name: "FAQs",
      text: "We answered some important questions you would like to ask us.",
      route: "/individual/faqs",
    },
    {
      id: 2,
      name: "Become a partner",
      text: "We are a fast growing cross-border payment platform. We will be happy to have you join us.",
      route: "/individual/partners",
    },
    {
      id: 3,
      name: "Community",
      text: "Join our community of great people whose mission is to serve a global audience at a click.",
      externalLink: "https://t.me/feedxpay",
    },
    {
      id: 4,
      name: "Contact Sales",
      text: "Do you need help with our services or would like to make enquiries? We got you covered.",
      route: "/individual/contactSale",
    },
    {
      id: 5,
      name: "Support",
      text: "Do you need to reach out to our team or would like to make enquiries? We got you covered.",
      route: "/individual/support",
    },
  ];

  return (
    <Modal3 onClose={props.onHideSupportModal}>
      <div className="py-[0.3rem] px-[0.3rem]">
        {support.map((data) =>
          data.route ? (
            <Link to={data.route} key={data.id}>
              <div className="bg-text py-4 px-2">
                <h1 className="pb-[0.2rem] text-[#1D1D1F] text-lg sm:text-[1.375rem] font-semibold leading-normal lg:leading-[2rem]">
                  {data.name}
                </h1>
                <p className="text-sm sm:text-[1.125rem] sm:pb-0 font-medium leading-[1.5rem] text-[#1D1D1F]">
                  {data.text}
                </p>
              </div>{" "}
            </Link>
          ) : (
            <a
              href={data.externalLink}
              target="_blank"
              rel="noopener noreferrer"
              key={data.id}
            >
              <div className="bg-text py-4 px-2">
                <h1 className="pb-[0.2rem] text-[#1D1D1F] text-lg sm:text-[1.375rem] font-semibold leading-normal lg:leading-[2rem]">
                  {data.name}
                </h1>
                <p className="text-sm sm:text-[1.125rem] sm:pb-0 font-medium leading-[1.5rem] text-[#1D1D1F]">
                  {data.text}
                </p>
              </div>
            </a>
          )
        )}
      </div>
    </Modal3>
  );
};

export default Support;
