import React, { useState, useRef } from "react";
import Card from "../../../UI/Card";
import Input from "../../../Layout/Routes/OpenAcct/Input";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Country, State, City } from "country-state-city";
import "react-toastify/dist/ReactToastify.css";
import Success from "../../../Success";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import SuccessModal from "../../../UI/SuccessModal";

const CustomInputs = () => {
  const [successModal, setSuccessModal] = useState(false);
  const firstnameInputRef = useRef(null);
  const lastnameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const contentInputRef = useRef(null);
  const phonenumberInputRef = useRef(null);
  const countryInputRef = useRef(null);

  const handleRequest = async (e) => {
    e.preventDefault();
    if (
      firstnameInputRef.current.value === "" ||
      lastnameInputRef.current.value === "" ||
      emailInputRef.current.value === "" ||
      !emailInputRef.current.value.includes("@") ||
      contentInputRef.current.value === "" ||
      phonenumberInputRef.current.value === "" ||
      !phonenumberInputRef.current.value.includes("+234") ||
      countryInputRef.current.value === ""
    ) {
      let errorMessage = "Please fill out all fields correctly";
      if (!phonenumberInputRef.current.value.includes("+234")) {
        errorMessage = "Please enter a phone number with the country code";
      }

      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        theme: "dark",
      });
      console.log("toast");
      return;
    }

    try {
      const formData = {
        first_name: firstnameInputRef.current.value,
        last_name: lastnameInputRef.current.value,
        email: emailInputRef.current.value,
        message: contentInputRef.current.value,
        phone_number: phonenumberInputRef.current.value,
        country: countryInputRef.current.value,
        message_type: "BecomeAPartner",
      };

      const response = await fetch("https://api.feedxpay.com/v1/site/support", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(data);

      if (response.ok) {
        console.log("success");
        setSuccessModal(true);
      } else {
        throw new Error("Something went wrong");
      }

      firstnameInputRef.current.value = "";
      lastnameInputRef.current.value = "";
      emailInputRef.current.value = "";
      contentInputRef.current.value = "";
      countryInputRef.current.value = "";
      phonenumberInputRef.current.value = "";
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <section
      className="bgs lg:h-[90.1875rem] m-auto sm:m-0 sm:h-[84.1875rem] h-[75.1875rem] flex flex-col justify-center sm:justify-center items-center sm:pt-[6.25rem] mt-[2rem]"
      id="section2"
    >
      <ToastContainer />

      <Card className="lg:h-[73.9375rem]  md:h-[71.9375rem] sm:shade md:w-[42.125rem] lg:w-[50.125rem] w-[21.567rem] sm:bg-white sm:rounded-2xl">
        <h1 className="sm:pt-[4.81rem] pt-[3.12rem] text-[1.125rem] text-center text-[#1D1D1F] md:text-[2rem] font-bold leading-normal sm:leading-[3rem] ">
          Apply to become Feedxpay partner
        </h1>
        <p className="sm:pt-[0.75rem] px-7 sm:px-0 pt-[0.81rem] text-sm sm:pb-[6.25rem] pb-[2.81rem] text-center text-[#1D1D1F] font-medium text-[1.125rem] leading-[1.5rem]">
          Fill out the form to confirm your decision of becoming
          <br className="hidden sm:block" />
          our partner. We will get back to you shortly{" "}
          <br className="hidden sm:block" /> on other procedures.
        </p>
        <form className="lg:px-[5.6rem] md:px-[3rem]" onSubmit={handleRequest}>
          <div className="flex flex-col sm:flex-row sm:gap-[2rem] sm:pb-[2rem]">
            <Input
              type="text"
              ref={firstnameInputRef}
              label="First Name"
              className="lg:w-[18.125rem] pl-5 w-[21.375rem] mb-[1.5rem] h-[3rem] md:w-[16.825rem] am:h-[3.75rem] border-2 border-[#E8E9EB] rounded-xl  focus:outline-blue-400"
            />

            <Input
              type="text"
              ref={lastnameInputRef}
              label="Last Name"
              className="lg:w-[18.125rem] pl-5 w-[21.375rem] mb-[1.5rem] h-[3rem] md:w-[16.825rem] sm:h-[3.75rem] border-2 border-[#E8E9EB] rounded-xl  focus:outline-blue-400"
            />
          </div>
          <Input
            type="text"
            ref={emailInputRef}
            label="Email Address"
            className="sm:w-full w-[21.375rem] pl-5 h-[3rem] sm:h-[3.75rem] mb-[1.5rem] sm:mb-[2rem] border-2 border-[#E8E9EB] rounded-xl  focus:outline-blue-400"
          />
          <Input
            type="text"
            ref={phonenumberInputRef}
            label="Phone Number"
            className="sm:w-full pl-5 w-[21.375rem] h-[3rem] sm:mb-[2rem] mb-[1.5rem] sm:h-[3.75rem] border-2 border-[#E8E9EB] rounded-xl  focus:outline-blue-400"
          />

          <div className="flex flex-col relative">
            <label className="pb-[0.5rem] text-[#1D1D1F] text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem]">
              Country
            </label>

            <select
              ref={countryInputRef}
              defaultValue=" -- Select a Country --"
              className="block appearance-none sm:mb-[2rem] mb-[1.5rem] sm:w-full w-[21.375rem] h-[3rem] sm:h-[2.75rem] px-5 py-2 border-2 border-[#E8E9EB] rounded-xl leading-5 focus:outline-none focus:border-blue-400"
            >
              <option value=" -- Select a Country --" disabled>
                -- Select a Country --
              </option>
              {Country.getAllCountries().map((items) => (
                <option key={items.isoCode} value={items.name}>
                  {items.name}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-3 top-2 flex items-center px-2 pointer-events-none">
              <MdOutlineKeyboardArrowDown className="text-xl" />
            </div>
          </div>
          <div className="pb-[4.37rem] flex flex-col">
            <label className="pb-[0.5rem] text-[ #1D1D1F] text-[0.875rem] font-medium leading-normal tracking-[-0.0175rem]">
              Add note
            </label>
            <textarea
              ref={contentInputRef}
              type="text"
              className="sm:w-full pl-5 sm:h-[9.0625rem] w-[21.375rem] h-[7.8125rem] border-2 border-[#E8E9EB] rounded-xl"
            />
          </div>
          <button
            type="submit"
            className="-mt-[2rem] bg-[#017A59] px-5 py-4 rounded-xl text-white text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]"
          >
            Become a Partner
          </button>
        </form>
      </Card>
      {successModal && <Success onClose={() => setSuccessModal(false)} />}
    </section>
  );
};

export default CustomInputs;
