import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-[#017A59] bg-opacity-20 z-40"
      onClick={props.onClose}
    />
  );
};

const Overlay = (props) => {
  return (
    <div className="overflow-y-scroll iphone7:top-[92.5vh] pb-64 fixed left-1/2 -translate-x-1/2 h-[29rem] -translate-y-1/2 iphoneXr:top-[65.8vh] pixel7:top-[65vh] top-[40vh] iphone12:top-[69.5vh] md:h-[37.7rem] md:top-[60.5vh] iphone14:top-[63.5vh]  lg:-translate-x-0 lg:-translate-y-0 w-[90%] md:w-[73%] xl:top-[23vh] lg:top-[26vh] xl:left-[25rem] lg:w-[47%] lg:left-[17.5rem] lg:h-[37.7rem] mac:left-[30rem] xl:w-[38%] lg:pb-44 mac:pb-28 mac:w-[34%] xl:h-[37.7rem] bg-white p-[0.5rem] lg:p-[1rem] rounded-xl shadow-2xl z-40">
      <div>{props.children}</div>
    </div>
  );
};
const portalElements = document.getElementById("overlay2");
const Modal3 = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElements
      )}
      {ReactDOM.createPortal(
        <Overlay>{props.children}</Overlay>,
        portalElements
      )}
    </>
  );
};

export default Modal3;
