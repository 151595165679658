import React, { useState, useEffect, useRef, useCallback } from "react";
import manufacturing from "../../Assets/Business/manufacturing.png";
import imports from "../../Assets/Business/imports.png";
import Logistics from "../../Assets/Business/Logistics.png";
import Financial from "../../Assets/Business/Financial.png";
import constructions from "../..//Assets/Business/constructions.png";
import media from "../../Assets/Business/media.png";
import tech from "../../Assets/Business/tech.png";
import travel from "../../Assets/Business/travel.png";
import "../../App.css";

const Industries = () => {
  const [activeSection, setActiveSection] = useState("MANUFACTURING");
  const [linePosition, setLinePosition] = useState(0);
  const [initialRender, setInitialRender] = useState(true);
  const activeSectionRef = useRef(null);

  const handleSection = useCallback((section) => {
    setActiveSection(section);
    const sectionElement = document.getElementById(section);
    const line = document.querySelector(".line3");

    if (sectionElement && line) {
      const sectionTop = sectionElement.offsetTop - 7.78 * 16;
      const lineStartPosition = line.offsetTop;
      const lineEndPosition = sectionTop + sectionElement.offsetHeight / 2;

      animateLine(line, lineStartPosition, lineEndPosition);
    }
    // console.log("rerender4");
  }, []);

  const animateLine = (line, startPosition, endPosition) => {
    const duration = 300;
    let startTime;

    const moveLine = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;

      const newPosition =
        progress < duration
          ? startPosition +
            ((endPosition - startPosition) * progress) / duration
          : endPosition;

      line.style.top = `${newPosition}px`;

      if (progress < duration) {
        requestAnimationFrame(moveLine);
      }
    };

    requestAnimationFrame(moveLine);
    // console.log("rerender3");
  };

  useEffect(() => {
    handleSection("MANUFACTURING");
    setInitialRender(false);
    // console.log("rerender1");
  }, [handleSection]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".sectioned");
      let closestSection = sections[0];

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 9.8375 * 16;
        if (window.scrollY >= sectionTop) {
          closestSection = section;
        }
      });

      const newActiveSection = closestSection.getAttribute("data-section");
      if (newActiveSection !== activeSectionRef.current) {
        setActiveSection(newActiveSection);
        activeSectionRef.current = newActiveSection;
      }
    };
    console.log("text-color");
    // console.log("rerender2");
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  const textColor1 =
    activeSection === "MANUFACTURING" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textColor2 =
    activeSection === "IMPORT & EXPORT" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textColor3 =
    activeSection === "FINANCIAL SOLUTIONS" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textColor4 =
    activeSection === "TECHNOLOGY" || initialRender ? "#017A59" : "#017A591A";
  const textColor5 =
    activeSection === "TRAVEL & HOSPITALITY" || initialRender
      ? "#017A59"
      : "#017A591A";
  const textColor6 =
    activeSection === "LOGISTICS" || initialRender ? "#017A59" : "#017A591A";
  const textColor7 =
    activeSection === "CONSTRUCTION" || initialRender ? "#017A59" : "#017A591A";
  const textColor8 =
    activeSection === "MEDIA & ENTERTAINMENT" || initialRender
      ? "#017A59"
      : "#017A591A";

  const textColors1 =
    activeSection === "MANUFACTURING" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textColors2 =
    activeSection === "IMPORT & EXPORT" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";
  const textColors3 =
    activeSection === "FINANCIAL SOLUTIONS" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";
  const textColors4 =
    activeSection === "TECHNOLOGY" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textColors5 =
    activeSection === "TRAVEL & HOSPITALITY" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";
  const textColors6 =
    activeSection === "LOGISTICS" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textColors7 =
    activeSection === "CONSTRUCTION" || initialRender ? "#1D1D1F" : "#e8e9eb";
  const textColors8 =
    activeSection === "MEDIA & ENTERTAINMENT" || initialRender
      ? "#1D1D1F"
      : "#e8e9eb";
  return (
    <>
      <div className="lg:pt-[6.25rem] hidden sm:block md:pt-[8.25rem] text-center">
        <h2 className="text-[#1D1D1F] md:text-[2.375rem] lg:text-[2.575rem] xl:text-[2.875rem] font-bold leading-[3.875rem] tracking-[-0.0575rem]">
          <span className="text-[#017A59]">Industries</span> that can use our
          service
        </h2>
        <p className="text-center pt-[1rem] md:text-base text-[#1D1D1F] lg:text-[1.175rem] xl:text-[1.375rem] font-medium lg:leading-[1.875rem] tracking-[-0.0275rem]">
          Being a cross-border payment platform makes our business services{" "}
          <br />
          useful to wide range of industries
        </p>
      </div>
      <section className="hidden sm:flex justify-around relative mac:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto">
        <div className="line-container3">
          <div
            className={`${"border-l-2 border-[#017a59] h-[9.8375rem] transition-all duration-300"} line3`}
            // style={{ top: `calc(${linePosition}px - 4.38rem)` }}
          ></div>
        </div>

        <div className="pt-[5.69rem] cursor-pointer">
          <h1
            style={{ color: `${textColor1}` }}
            onClick={() => handleSection("MANUFACTURING")}
            className={`pb-[0.5rem] text-[${
              activeSection === "MANUFACTURING" ? "#017A59" : "#e8e9eb"
            }]
              md:text-[1.8rem] lg:text-[2.125rem] font-bold leading-[2.875rem] tracking-[-0.0425rem] sectioned`}
            data-section="MANUFACTURING"
            id="MANUFACTURING"
          >
            MANUFACTURING
          </h1>
          <p
            style={{ color: `${textColors1}` }}
            className={`text-[${
              activeSection === "MANUFACTURING" || initialRender
                ? "#1D1D1F"
                : "#e8e9eb"
            }] pb-[3.13rem]  md:text-base mac:text-[1.25rem] xl:text-[1.1rem] lg:text-[0.9rem] font-medium leading-[1.75rem] tracking-[-0.025rem]`}
          >
            Seamlessly pay suppliers globally, collect
            <br /> payment internationally and locally.
          </p>

          <h1
            style={{ color: `${textColor2}` }}
            onClick={() => handleSection("IMPORT & EXPORT")}
            className={`pb-[0.5rem] text-[${
              activeSection === "IMPORT & EXPORT" ? "#017A59" : "#e8e9eb"
            }] md:text-[1.8rem] lg:text-[2.125rem]  font-bold leading-[2.875rem] tracking-[-0.0425rem]  `}
            data-section="IMPORT & EXPORT"
            id="IMPORT & EXPORT"
          >
            IMPORT & EXPORT
          </h1>
          <p
            style={{ color: `${textColors2}` }}
            className={`text-[${
              activeSection === "IMPORT & EXPORT" ? "#1D1D1F" : "#e8e9eb"
            }] pb-[3.13rem] mac:text-[1.25rem] xl:text-[1.1rem] lg:text-[0.9rem] font-medium leading-[1.75rem] tracking-[-0.025rem]`}
          >
            Collect payment from international buyers, <br />
            and send funds to their local accounts.
          </p>

          <h1
            style={{ color: `${textColor3}` }}
            onClick={() => handleSection("FINANCIAL SOLUTIONS")}
            className={`pb-[0.5rem] text-[${
              activeSection === "FINANCIAL SOLUTIONS" ? "#017A59" : "#e8e9eb"
            }] md:text-[1.8rem] lg:text-[2.125rem]  font-bold leading-[2.875rem] tracking-[-0.0425rem] `}
            data-section="FINANCIAL SOLUTIONS"
            id="FINANCIAL SOLUTIONS"
          >
            FINANCIAL SOLUTIONS
          </h1>
          <p
            style={{ color: `${textColors3}` }}
            className={`text-[${
              activeSection === "FINANCIAL SOLUTIONS" ? "#1D1D1F" : "#e8e9eb"
            }] pb-[3.13rem] mac:text-[1.25rem] xl:text-[1.1rem] lg:text-[0.9rem] font-medium leading-[1.75rem] tracking-[-0.025rem]`}
          >
            Get liquidity access to power your cross-
            <br />
            border payments.
          </p>

          <h1
            style={{ color: `${textColor4}` }}
            onClick={() => handleSection("TECHNOLOGY")}
            className={`pb-[0.5rem] text-[${
              activeSection === "TECHNOLOGY" || initialRender
                ? "#017A59"
                : "#e8e9eb"
            }] md:text-[1.8rem] lg:text-[2.125rem]  font-bold leading-[2.875rem] tracking-[-0.0425rem]  `}
            data-section="TECHNOLOGY"
            id="TECHNOLOGY"
          >
            TECHNOLOGY
          </h1>
          <p
            style={{ color: `${textColors4}` }}
            className={` text-[${
              activeSection === "TECHNOLOGY" || initialRender
                ? "#1D1D1F"
                : "#e8e9eb"
            }] pb-[3.13rem] mac:text-[1.25rem] xl:text-[1.1rem] lg:text-[0.9rem] font-medium leading-[1.75rem] tracking-[-0.025rem]`}
          >
            Effortlessly manage global payments to
            <br />
            suppliers and efficiently receive payments.
          </p>

          <h1
            style={{ color: `${textColor5}` }}
            onClick={() => handleSection("TRAVEL & HOSPITALITY")}
            className={`pb-[0.5rem] text-[${
              activeSection === "TRAVEL & HOSPITALITY" || initialRender
                ? "#017A59"
                : "#e8e9eb"
            }] md:text-[1.8rem] lg:text-[2.125rem]  font-bold leading-[2.875rem] tracking-[-0.0425rem]  `}
            data-section="TRAVEL & HOSPITALITY"
            id="TRAVEL & HOSPITALITY"
          >
            TRAVEL & HOSPITALITY
          </h1>
          <p
            style={{ color: `${textColors5}` }}
            className={`text-[${
              activeSection === "TRAVEL & HOSPITALITY" || initialRender
                ? "#1D1D1F"
                : "#e8e9eb"
            }] pb-[3.13rem] mac:text-[1.25rem] xl:text-[1.1rem] lg:text-[0.9rem] font-medium leading-[1.75rem] tracking-[-0.025rem]`}
          >
            Enjoy unforgettable travel experience with
            <br /> our secure payment platform.
          </p>

          <h1
            style={{ color: `${textColor6}` }}
            onClick={() => handleSection("LOGISTICS")}
            className={`pb-[0.5rem] text-[${
              activeSection === "LOGISTICS" || initialRender
                ? "#017A59"
                : "#e8e9eb"
            }] md:text-[1.8rem] lg:text-[2.125rem]  font-bold leading-[2.875rem] tracking-[-0.0425rem]  `}
            data-section="LOGISTICS"
            id="LOGISTICS"
          >
            LOGISTICS
          </h1>
          <p
            style={{ color: `${textColors6}` }}
            className={`text-[${
              activeSection === "LOGISTICS" || initialRender
                ? "#1D1D1F"
                : "#e8e9eb"
            }] pb-[3.13rem] mac:text-[1.25rem] xl:text-[1.1rem] lg:text-[0.9rem] font-medium leading-[1.75rem] tracking-[-0.025rem]`}
          >
            Logistics businesses can collect and send <br />
            funds globally at a very low cost.
          </p>

          <h1
            style={{ color: `${textColor7}` }}
            onClick={() => handleSection("CONSTRUCTION")}
            className={`pb-[0.5rem] text-[${
              activeSection === "CONSTRUCTION" || initialRender
                ? "#017A59"
                : "#e8e9eb"
            }] md:text-[1.8rem] lg:text-[2.125rem]  font-bold leading-[2.875rem] tracking-[-0.0425rem]  `}
            data-section="CONSTRUCTION"
            id="CONSTRUCTION"
          >
            CONSTRUCTION
          </h1>
          <p
            style={{ color: `${textColors7}` }}
            className={`text-[${
              activeSection === "CONSTRUCTION" || initialRender
                ? "#1D1D1F"
                : "#e8e9eb"
            }] pb-[3.13rem]  mac:text-[1.25rem] xl:text-[1.1rem] lg:text-[0.9rem] font-medium leading-[1.75rem] tracking-[-0.025rem]`}
          >
            Expand your operations beyond border <br />
            with our cross-border payment solutions.
          </p>

          <h1
            style={{ color: `${textColor8}` }}
            onClick={() => handleSection("MEDIA & ENTERTAINMENT")}
            className={`pb-[0.5rem] text-[${
              activeSection === "MEDIA & ENTERTAINMENT" || initialRender
                ? "#017A59"
                : "#e8e9eb"
            }] md:text-[1.8rem] lg:text-[2.125rem]  font-bold leading-[2.875rem] tracking-[-0.0425rem]  `}
            data-section="MEDIA & ENTERTAINMENT"
            id="MEDIA & ENTERTAINMENT"
          >
            MEDIA & ENTERTAINMENT
          </h1>
          <p
            style={{ color: `${textColors8}` }}
            className={`text-[${
              activeSection === "MEDIA & ENTERTAINMENT" || initialRender
                ? "#1D1D1F"
                : "#e8e9eb"
            }] pb-[3.13rem] mac:text-[1.25rem] xl:text-[1.1rem] lg:text-[0.9rem] font-medium leading-[1.75rem] tracking-[-0.025rem]`}
          >
            Give global customers a truly modern <br />
            payments experience.
          </p>
        </div>

        {activeSection === "MANUFACTURING" ? (
          <div>
            <img
              src={manufacturing}
              alt=""
              className="object-cover rounded-[8px] mac:w-[38.1875rem] lg:h-[75.1875rem] xl:mt-[5rem] xl:w-[35.1875rem] lg:w-[25.1875rem] pro:w-[20.1875rem] pro:h-[27rem] pro:mt-20 lg:mt-20 md:w-[16rem] md:h-[22rem] md:mt-20"
            />
          </div>
        ) : "" || activeSection === "IMPORT & EXPORT" ? (
          <div>
            <img
              src={imports}
              alt=""
              className="object-cover rounded-[8px] mac:w-[38.1875rem] lg:h-[75.1875rem] xl:mt-[5rem] xl:w-[35.1875rem] lg:w-[25.1875rem] pro:w-[20.1875rem] pro:h-[27rem] pro:mt-24 lg:mt-20 md:w-[16rem] md:h-[22rem] md:mt-[9rem]"
            />
          </div>
        ) : "" || activeSection === "FINANCIAL SOLUTIONS" ? (
          <div className="">
            <img
              src={Financial}
              alt=""
              className="object-cover rounded-[8px] mac:w-[38.1875rem] lg:h-[75.1875rem] xl:mt-[5rem] xl:w-[35.1875rem] lg:w-[25.1875rem] pro:w-[20.1875rem] pro:h-[27rem] pro:mt-32 lg:mt-20 md:w-[16rem] md:h-[22rem] md:mt-[13rem]"
            />
          </div>
        ) : "" || activeSection === "TECHNOLOGY" ? (
          <div className="">
            <img
              src={tech}
              alt=""
              className="object-cover rounded-[8px] mac:w-[38.1875rem] lg:h-[75.1875rem] xl:mt-[5rem] xl:w-[35.1875rem] lg:w-[25.1875rem] pro:w-[20.1875rem] pro:h-[27rem] pro:mt-[18rem] lg:mt-20 md:w-[16rem] md:h-[22rem] md:mt-[22rem]"
            />
          </div>
        ) : "" || activeSection === "TRAVEL & HOSPITALITY" ? (
          <div className="">
            <img
              src={travel}
              alt=""
              className="object-cover rounded-[8px] mac:w-[38.1875rem] lg:h-[75.1875rem] xl:mt-[5rem] xl:w-[35.1875rem] lg:w-[25.1875rem] pro:w-[20.1875rem] pro:h-[27rem] pro:mt-[27rem] lg:mt-20 md:w-[16rem] md:h-[22rem] md:mt-[31rem]"
            />
          </div>
        ) : "" || activeSection === "LOGISTICS" ? (
          <div className="">
            <img
              src={Logistics}
              alt=""
              className="object-cover rounded-[8px] mac:w-[38.1875rem] lg:h-[75.1875rem] xl:mt-[5rem] xl:w-[35.1875rem] lg:w-[25.1875rem] pro:w-[20.1875rem] pro:h-[27rem] pro:mt-[37rem] lg:mt-20 md:w-[16rem] md:h-[22rem] md:mt-[41rem]"
            />
          </div>
        ) : "" || activeSection === "CONSTRUCTION" ? (
          <div className="">
            <img
              src={constructions}
              alt=""
              className="object-cover rounded-[8px] mac:w-[38.1875rem] lg:h-[75.1875rem] xl:mt-[5rem] xl:w-[35.1875rem] lg:w-[25.1875rem] pro:w-[20.1875rem] pro:h-[27rem] pro:mt-[47rem] lg:mt-20 md:w-[16rem] md:h-[22rem] md:mt-[51rem]"
            />
          </div>
        ) : "" || activeSection === "MEDIA & ENTERTAINMENT" ? (
          <div className="">
            <img
              src={media}
              alt=""
              className="object-cover rounded-[8px] mac:w-[38.1875rem] lg:h-[75.1875rem] xl:mt-[5rem] xl:w-[35.1875rem] lg:w-[25.1875rem] pro:w-[20.1875rem] pro:h-[27rem] pro:mt-[57rem] lg:mt-20 md:w-[16rem] md:h-[22rem] md:mt-[61rem]"
            />
          </div>
        ) : (
          ""
        )}
      </section>
    </>
  );
};

export default Industries;
