import React, { useState } from "react";
import "../../../App.css";
import Logo from "../../../Assets/Logo.png";
import { TiArrowSortedDown } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";
import { HashLink as HashRouterLink } from "react-router-hash-link";
import { Squash as Hamburger } from "hamburger-react";
import { IoIosArrowDown } from "react-icons/io";

const Nav = (props) => {
  const [nav, setNav] = useState(false);
  const location = useLocation();

  // const handleClick = () => {
  //   setNav(false);
  // };

  return (
    <section className="bg">
      <nav className="lg:fixed fixed left-0 top-0 w-full bg h-[8.63rem] lg:h-[10.5rem] z-30">
        <div
          className={`flex py-5 mac:max-w-[83rem] pro:max-w-[52rem] max-w-[22rem] xl:max-w-[75rem] md:max-w-[45rem] lg:max-w-[60rem] mx-auto lg:space-x-[5rem] space-x-[2rem] ${
            nav ? "hidden lg:block" : "lg:block"
          }`}
        >
          <Link to="/">
            <button
              className={
                location.pathname === "/" ||
                location.pathname.startsWith("/aboutUs") ||
                location.pathname.startsWith("/careers") ||
                location.pathname.startsWith("/faq") ||
                location.pathname.startsWith("/partner") ||
                location.pathname.startsWith("/contactSales") ||
                location.pathname.startsWith("/privacy") ||
                location.pathname.startsWith("/support")
                  ? "active text-[#017A59] uppercase pb-2 text-xs xl:text-base cursor-pointer border-b-[3px] border-[#017A59] font-semibold leading-normal tracking-[-0.02rem]"
                  : "text-[#8A9099] uppercase text-xs xl:text-[1rem] cursor-pointer font-semibold leading-normal tracking-[-0.02rem]"
              }
            >
              Business
            </button>
          </Link>
          <Link to="/individual">
            <button
              className={
                location.pathname === "/individual" ||
                location.pathname.startsWith("/payment") ||
                location.pathname.startsWith("/virtualcards") ||
                location.pathname.startsWith("/savings") ||
                location.pathname.startsWith("/investments") ||
                location.pathname.startsWith("/individual/aboutus") ||
                location.pathname.startsWith("/individual/career") ||
                location.pathname.startsWith("/individual/faqs") ||
                location.pathname.startsWith("/individual/partners") ||
                location.pathname.startsWith("/individual/contactSale") ||
                location.pathname.startsWith("/policy") ||
                location.pathname.startsWith("/individual/support")
                  ? "active text-[#017A59] uppercase text-xs xl:text-base pb-2 cursor-pointer border-b-[3px] border-[#017A59] font-semibold leading-normal tracking-[-0.02rem]"
                  : "text-[#8A9099] uppercase text-xs xl:text-[1rem] cursor-pointer font-semibold leading-normal tracking-[-0.02rem]"
              }
            >
              Individuals
            </button>
          </Link>
        </div>

        <div
          className={`flex justify-between max-w-[22rem] mx-auto py-[0.85rem] md:max-w-[45rem] pro:max-w-[52rem] lg:hidden ${
            nav ? "pt-[4.63rem]" : ""
          } ${nav ? "max-w-[22.5rem]" : ""}`}
        >
          <Link to="/">
            <img
              src={Logo}
              alt=""
              className="lg:w-[14.5rem] lg:h-[2.5rem] w-[8.4375rem] h-[1.4375rem] lg:hidden block"
            />{" "}
          </Link>
          <div className="lg:hidden block -mt-3">
            <Hamburger
              direction="squash"
              size={25}
              color="black"
              className="burger-menu"
              toggled={nav}
              toggle={() => setNav(!nav)}
            />
          </div>
        </div>
        {nav && (
          <div
            className={`w-full bg h-screen iphone7:h-[35rem] iphone12:h-screen overflow-y-auto z-50 lg:hidden flex flex-col justify-between ${
              nav ? "show" : "hide"
            }`}
          >
            <ul className="p-3 space-y-7 pro:space-y-16 m-auto max-w-[22.5rem] md:max-w-[44.5rem] md:p-1 pt-[3.19rem] mt-0 md:pt-[4.19rem] pro:max-w-[53rem]  pro:pt-[5.19rem] w-full">
              <li
                onClick={props.onClick}
                className="flex justify-between border-b-2 pro:pb-[2rem] pb-[1.19rem] border-[#017A591A]/10 cursor-pointer text-[#1D1D1F] text-[1rem] font-semibold leading-[1.5rem] tracking-[-0.02rem]"
              >
                Products <IoIosArrowDown className="mt-1 text-xl md:text-2xl" />
              </li>
              <li
                onClick={props.onOpen}
                className="flex justify-between  border-b-2 pb-[1.19rem] pro:pb-[2rem] border-[#017A591A]/10 cursor-pointer text-[#1D1D1F] text-[1rem] font-semibold leading-[1.5rem] tracking-[-0.02rem]"
              >
                Company <IoIosArrowDown className="mt-1 text-xl md:text-2xl" />
              </li>
              <li
                onClick={props.onSet}
                className="flex justify-between  border-b-2 pb-[1.19rem] pro:pb-[2rem] border-[#017A591A]/10 cursor-pointer text-[#1D1D1F] text-[1rem] font-semibold leading-[1.5rem] tracking-[-0.02rem]"
              >
                Support <IoIosArrowDown className="mt-1 text-xl md:text-2xl" />
              </li>
            </ul>
            <div className="flex flex-col pb-[1rem] pro:max-w-[53rem] m-auto space-y-[1.5rem] justify-end max-w-[21.5rem] w-full md:max-w-[44.5rem] pro:ma-w-[53rem]">
              <button className="border-[#017A59] border-2 text-base w-full py-4 text-[#017A59] rounded-lg">
                SignIn
              </button>
              <button className="bg-[#017A59] text-base w-full py-4 text-white rounded-lg">
                Open Account
              </button>
            </div>
          </div>
        )}
        <div className="lg:flex hidden justify-between mac:max-w-[83rem] xl:max-w-[75rem] lg:max-w-[60rem] mx-auto py-[1.17rem]">
          <Link to="/">
            <img src={Logo} alt="" className="w-[14.5rem] h-[2.5rem] " />
          </Link>

          <ul className="flex space-x-[3.12rem] mt-3">
            <li
              className="flex gap-1 cursor-pointer text-[#1D1D1F] text-[1.0625rem] font-semibold leading-[1.5rem] tracking-[-0.02125rem]"
              onClick={props.onClick}
            >
              Products <TiArrowSortedDown className="mt-1" />{" "}
            </li>

            <li
              onClick={props.onOpen}
              className="flex gap-1 cursor-pointer text-[#1D1D1F] text-[1.0625rem] font-semibold leading-[1.5rem] tracking-[-0.02125rem]"
            >
              Company <TiArrowSortedDown className="mt-1" />
            </li>

            <li
              onClick={props.onSet}
              className="flex gap-1 cursor-pointer text-[#1D1D1F] text-[1.0625rem] font-semibold leading-[1.5rem] tracking-[-0.02125rem]"
            >
              Support <TiArrowSortedDown className="mt-1" />
            </li>
          </ul>

          <div className="flex space-x-[2rem]">
            {/* <p className="mt-3 text-[#1D1D1F] text-[1.0625rem] font-semibold leading-[1.5rem] tracking-[-0.02125rem]">
              <Link to="/signIn">Sign in</Link>
            </p> */}
            <HashRouterLink smooth to="#section1">
              <button className="bg-[#017A59] text-white hidden lg:block px-5 py-3 rounded-xl  text-[1.125rem] font-semibold leading-[1.5rem] tracking-[-0.0225rem]">
                Get Started
              </button>
            </HashRouterLink>
          </div>
        </div>
      </nav>
    </section>
  );
};

export default Nav;
