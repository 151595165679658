import React from "react";
import ReactDOM from "react-dom";

const Backdrop = (props) => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-[#017A59] bg-opacity-20 z-40"
      onClick={props.onClose}
    />
  );
};

const Overlay = (props) => {
  return (
    <div className="fixed iphone7:top-[72.5vh] iphone7:h-[22.5rem]  left-1/2 -translate-x-1/2 -translate-y-1/2 md:h-[23rem] iphoneXr:top-[50.5vh] pixel7:top-[51vh] top-[40vh] iphone12:top-[54vh] iphone14:top-[48.5vh] lg:-translate-x-0 lg:-translate-y-0 md:top-[47.5vh] md:w-[73%] xl:top-[23vh] lg:top-[27vh] w-[90%] mac:left-[44.5rem] xl:left-[39.5rem] lg:left-[31.5rem] lg:w-[43%] xl:w-[34%] h-[21.5rem] lg:h-[23.5rem] bg-white p-[0.8rem] lg:p-[1rem] rounded-xl shadow-2xl z-40">
      <div>{props.children}</div>
    </div>
  );
};
const portalElements = document.getElementById("overlay");
const Modal2 = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElements
      )}
      {ReactDOM.createPortal(
        <Overlay>{props.children}</Overlay>,
        portalElements
      )}
    </>
  );
};

export default Modal2;
